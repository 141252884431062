export const UPLOAD_DELIVERABLE_FILE = 'UPLOAD_DELIVERABLE_FILE_NEO';
export const DELETE_DELIVERABLE_FILE = 'FETCH_DELIVERABLE_FILE_NEO';



export const uploadDeliverableFile = (context, deliverableId, deliverableVersion, deliverableType, file) => {
    return {
        type: UPLOAD_DELIVERABLE_FILE,
        payload: {
            context,
            deliverableId,
            deliverableVersion,
            deliverableType,
            file,
        },
    };
};


export const deleteDeliverableFile = (deliverableId, fileName) => {
    return {
        type: DELETE_DELIVERABLE_FILE,
        payload: {
            deliverableId,
            fileName,
        },
    };
};


