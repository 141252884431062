export const FETCH_CM4G_COUNT = 'FETCH_CM4G_COUNT';
export const FETCH_CM4GS = 'FETCH_CM4GS'

export const fetchCm4gCount = () => {
    return {
        type: FETCH_CM4G_COUNT,
    };
};

export const fetchCm4gs = () => {
    return {
        type: FETCH_CM4GS,
    };
};
