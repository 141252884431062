import { connect } from 'react-redux';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import DeliverableList from '~/features/deliverables/components/DeliverableList';
import {
    canDeleteDeliverablesSelector,
    canUpdateDeliverablesSelector,
    deliverableIdsItemSelector,
    deliverableIdsTotalCountSelector,
    deliverablesLoadingSelector,
} from '~/features/deliverables/selectors/deliverableSelectors';
import {
    showDeliverableDeletionDialog,
    showDeliverableEditorDialog,
    showDeliverableVersionEditorDialog,
} from '~/features/deliverables/actions/deliverableActions';

export const mapStateToProps = (state, ownProps) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        deliverablesLoading: deliverablesLoadingSelector(state),
        deliverableIdsTotalCount: deliverableIdsTotalCountSelector(state),
        showLoadMore: deliverableIdsItemSelector(state) < deliverableIdsTotalCountSelector(state),
        canUpdateDeliverablesSelector: canUpdateDeliverablesSelector(state),
        canDeleteDeliverablesSelector: canDeleteDeliverablesSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        onCreateDeliverable: (options) => {
            dispatch(showDeliverableEditorDialog(options));
        },
        onCreateDeliverableVersion: (options) => {
            dispatch(showDeliverableVersionEditorDialog(options));
        },
        onEditDeliverable: (options) => {
            dispatch(showDeliverableEditorDialog(options));
        },
        onDeleteDeliverable: (options) => {
            dispatch(showDeliverableDeletionDialog(options));
        },
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableList);
