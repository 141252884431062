import {combineReducers} from '@reduxjs/toolkit';
import {connectRouter} from 'connected-react-router';

import reducers from '~/features/base/reducers';

import configReducer from '~/setup/configReducer';
import appReducer from '~/features/app/reducer';
import langReducer from '~/features/lang/reducer';
import loginReducer from '~/features/login/reducer';
import sessionReducer from '~/features/login/sessionReducer';
import tokenHandlingReducer from '~/features/tokenHandling/reducer';
import appsReducer from '~/features/apps/reducers/appsReducer';
import {vehicleManagementApi} from '~/api/vehicles/VehicleManagement.api';
import {loggingServiceApi} from '~/api/logs/LoggingService.api';
import {controlDeviceApi} from '~/api/controlDevice/ControlDevice.api';
import {vehicleGroupApi} from '~/api/vehicleGroup/VehicleGroup.api';
import {deliverableManagementApi} from '~/api/deliverables/DeliverablesManagement.api';
import {deviceActionServiceApi} from '~/api/deviceAction/DeviceAction.api';

export const setupRootReducer = (history) => {
    const combinedReducers = combineReducers({
        router: connectRouter(history),
        app: appReducer,
        apps: appsReducer,
        config: configReducer,
        lang: langReducer,
        login: loginReducer,
        session: sessionReducer,
        tokenHandling: tokenHandlingReducer,
        [vehicleManagementApi.reducerPath]: vehicleManagementApi.reducer,
        [loggingServiceApi.reducerPath]: loggingServiceApi.reducer,
        [deviceActionServiceApi.reducerPath]: deviceActionServiceApi.reducer,
        [controlDeviceApi.reducerPath]: controlDeviceApi.reducer,
        [vehicleGroupApi.reducerPath]: vehicleGroupApi.reducer,
        [deliverableManagementApi.reducerPath]: deliverableManagementApi.reducer,
        //...sessionReducers,
        ...reducers,
    });

    // TODO Is this shizzle still needed? It should not be the case
    return (state, action) => {
        if (action.type === 'LOGOUT') {
            return combinedReducers(undefined, action);
        }
        return combinedReducers(state, action);
    };
};
