import { combineReducers } from '@reduxjs/toolkit';

import {
    ENTITY_ARTIFACT,
    ENTITY_ARTIFACT_FILES,
    ENTITY_BASE_SW,
    ENTITY_BASE_SW_VERSIONS,
    ENTITY_CM4G,
    ENTITY_CM4G_DELIVERABLE,
    ENTITY_CONTROL_DEVICE,
    ENTITY_CONTROL_DEVICE_ELIGIBLE_CM4G_VERSIONS,
    ENTITY_CONTROL_DEVICE_ELIGIBLE_FILE_VERSIONS,
    ENTITY_CONTROL_DEVICE_INFO,
    ENTITY_CONTROL_DEVICE_OPERATION,
    ENTITY_CONTROL_DEVICE_REFERENCED_DELIVERABLES,
    ENTITY_CONTROL_DEVICE_ROLLOUT_STATES,
    ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS,
    ENTITY_DEFAULT_NETWORK_CODE_LIST,
    ENTITY_DELIVERABLE,
    ENTITY_DELIVERABLE_HISTORY,
    ENTITY_DELIVERABLE_ID,
    ENTITY_DELIVERABLES_CONTEXT_INFO,
    ENTITY_DELIVERABLES_USER_INFO,
    ENTITY_DEVICE_ACTION,
    ENTITY_DEVICE_COUNTS_BY_COUNTRIES,
    ENTITY_DEVICE_COUNTS_BY_VEHICLES,
    ENTITY_DEVICE_GROUP,
    ENTITY_DEVICE_LOGS,
    ENTITY_FILE,
    ENTITY_FILE_DELIVERABLE,
    ENTITY_GROUP,
    ENTITY_GROUP_REFERENCES,
    ENTITY_HW_VARIANTS_ALL,
    ENTITY_LIVE_BUNDLES,
    ENTITY_LIVE_CM4GS,
    ENTITY_UPDATE_EVENT,
    ENTITY_USER,
    ENTITY_VEHICLE_INFO,
    ENTITY_VEHICLE_SPARE_PART_NUMBERS_ALL,
    ENTITY_VEHICLE_VARIANTS_ALL,
    ENTITY_VEHICLES,
    IDENTIFIER_ARTIFACT,
    IDENTIFIER_ARTIFACT_FILE,
    IDENTIFIER_BASE_SW,
    IDENTIFIER_CONTROL_DEVICE,
    IDENTIFIER_CONTROL_DEVICE_OPERATION,
    IDENTIFIER_CONTROL_DEVICE_ROLLOUT_STATE,
    IDENTIFIER_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATION,
    IDENTIFIER_DEVICE_ACTION,
    IDENTIFIER_FILE,
    IDENTIFIER_FILE_DELIVERABLE,
    IDENTIFIER_GENERIC_DELIVERABLE,
    IDENTIFIER_GROUP,
    IDENTIFIER_GROUP_REFERENCES,
    IDENTIFIER_UPDATE_EVENT,
    IDENTIFIER_VEHICLE,
} from '~/features/base/constants/entities';
// higher order
import createEntityMapReducer from '~/features/higherorder/reducers/entityMapReducer';
import createEntityReducer from '~/features/higherorder/reducers/entityReducer';
import createMapReducer from '~/features/higherorder/reducers/mapReducer';

export const entitiesReducer = combineReducers({
    [ENTITY_CONTROL_DEVICE]: createEntityMapReducer(ENTITY_CONTROL_DEVICE, IDENTIFIER_CONTROL_DEVICE),
    [ENTITY_VEHICLES]: createEntityMapReducer(ENTITY_VEHICLES, IDENTIFIER_VEHICLE),
    [ENTITY_DELIVERABLE]: createEntityReducer(ENTITY_DELIVERABLE),
    [ENTITY_DELIVERABLE_HISTORY]: createEntityReducer(ENTITY_DELIVERABLE_HISTORY),
    [ENTITY_ARTIFACT]: createEntityMapReducer(ENTITY_ARTIFACT, IDENTIFIER_ARTIFACT),
    [ENTITY_ARTIFACT_FILES]: createEntityMapReducer(ENTITY_ARTIFACT_FILES, IDENTIFIER_ARTIFACT_FILE),
    [ENTITY_DELIVERABLE_ID]: createEntityReducer(ENTITY_DELIVERABLE_ID),
    [ENTITY_CONTROL_DEVICE_INFO]: createEntityMapReducer(ENTITY_CONTROL_DEVICE_INFO, IDENTIFIER_CONTROL_DEVICE),
    [ENTITY_VEHICLE_INFO]: createEntityMapReducer(ENTITY_VEHICLE_INFO, IDENTIFIER_VEHICLE),
    [ENTITY_CONTROL_DEVICE_REFERENCED_DELIVERABLES]: createEntityReducer(
        ENTITY_CONTROL_DEVICE_REFERENCED_DELIVERABLES),
    [ENTITY_CONTROL_DEVICE_ELIGIBLE_FILE_VERSIONS]: createEntityReducer(
        ENTITY_CONTROL_DEVICE_ELIGIBLE_FILE_VERSIONS),
    [ENTITY_CONTROL_DEVICE_ELIGIBLE_CM4G_VERSIONS]: createEntityReducer(
        ENTITY_CONTROL_DEVICE_ELIGIBLE_CM4G_VERSIONS),
    [ENTITY_CONTROL_DEVICE_OPERATION]: createMapReducer(ENTITY_CONTROL_DEVICE_OPERATION, IDENTIFIER_CONTROL_DEVICE,
        createEntityMapReducer(ENTITY_CONTROL_DEVICE_OPERATION, IDENTIFIER_CONTROL_DEVICE_OPERATION)),
    [ENTITY_CONTROL_DEVICE_ROLLOUT_STATES]: createMapReducer(ENTITY_CONTROL_DEVICE_ROLLOUT_STATES,
        IDENTIFIER_CONTROL_DEVICE,
        createEntityMapReducer(ENTITY_CONTROL_DEVICE_ROLLOUT_STATES, IDENTIFIER_CONTROL_DEVICE_ROLLOUT_STATE)),

    [ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS]: createEntityMapReducer(
        ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS, IDENTIFIER_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATION),
    [ENTITY_DEVICE_LOGS]: createEntityMapReducer(ENTITY_DEVICE_LOGS, IDENTIFIER_CONTROL_DEVICE),
    [ENTITY_GROUP]: createEntityMapReducer(ENTITY_GROUP, IDENTIFIER_GROUP),
    [ENTITY_DEVICE_GROUP]: createEntityMapReducer(ENTITY_DEVICE_GROUP, IDENTIFIER_CONTROL_DEVICE),
    [ENTITY_DEVICE_COUNTS_BY_COUNTRIES]: createEntityReducer(ENTITY_DEVICE_COUNTS_BY_COUNTRIES),
    [ENTITY_DEVICE_COUNTS_BY_VEHICLES]: createEntityReducer(ENTITY_DEVICE_COUNTS_BY_VEHICLES),
    [ENTITY_GROUP_REFERENCES]: createEntityMapReducer(ENTITY_GROUP_REFERENCES, IDENTIFIER_GROUP_REFERENCES),
    [ENTITY_LIVE_CM4GS]: createEntityReducer(ENTITY_LIVE_CM4GS),
    [ENTITY_LIVE_BUNDLES]: createEntityReducer(ENTITY_LIVE_BUNDLES),
    // TODO We should just store the id of latest distro
    [ENTITY_BASE_SW]: createEntityMapReducer(ENTITY_BASE_SW, IDENTIFIER_BASE_SW),
    [ENTITY_BASE_SW_VERSIONS]: createEntityReducer(ENTITY_BASE_SW_VERSIONS),
    [ENTITY_HW_VARIANTS_ALL]: createEntityReducer(ENTITY_HW_VARIANTS_ALL),
    [ENTITY_VEHICLE_VARIANTS_ALL]: createEntityReducer(ENTITY_VEHICLE_VARIANTS_ALL),
    [ENTITY_VEHICLE_SPARE_PART_NUMBERS_ALL]: createEntityReducer(ENTITY_VEHICLE_SPARE_PART_NUMBERS_ALL),
    [ENTITY_CM4G]: createEntityReducer(ENTITY_CM4G),
    [ENTITY_CM4G_DELIVERABLE]: createEntityMapReducer(ENTITY_CM4G_DELIVERABLE, IDENTIFIER_GENERIC_DELIVERABLE),
    [ENTITY_FILE]: createEntityMapReducer(ENTITY_FILE, IDENTIFIER_FILE),
    [ENTITY_FILE_DELIVERABLE]: createEntityMapReducer(ENTITY_FILE_DELIVERABLE, IDENTIFIER_FILE_DELIVERABLE),
    [ENTITY_DELIVERABLES_USER_INFO]: createEntityReducer(ENTITY_DELIVERABLES_USER_INFO),
    [ENTITY_DELIVERABLES_CONTEXT_INFO]: createEntityReducer(ENTITY_DELIVERABLES_CONTEXT_INFO),
    [ENTITY_DEVICE_ACTION]: createEntityMapReducer(ENTITY_DEVICE_ACTION, IDENTIFIER_DEVICE_ACTION),
    [ENTITY_USER]: createEntityReducer(ENTITY_USER),
    [ENTITY_UPDATE_EVENT]: createEntityMapReducer(ENTITY_UPDATE_EVENT, IDENTIFIER_UPDATE_EVENT),
    [ENTITY_DEFAULT_NETWORK_CODE_LIST]: createEntityReducer(ENTITY_DEFAULT_NETWORK_CODE_LIST),
});
export default entitiesReducer;
