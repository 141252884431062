import forEach from 'lodash/fp/forEach';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import { Group } from '~/prop-types/groupPropType';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import SerialNumbersFormItem from '~/features/base/components/forms/SerialNumbersFormItem';
import VINListFormItem from '~/features/base/components/forms/VINListFormItem';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import AutoSuggest from '@rio-cloud/rio-uikit/lib/es/AutoSuggest';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';

// TODO Think about connecting this component so it can fetch groups on its own
export class WhitelistingDetailsEditorFormItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupWhitelistInputText: '',
            groupBlacklistInputText: '',
        };
    }

    onWhitelistedForAllChange = (event) => {
        const whitelistedForAll = !!event.target.checked;
        this.props.onWhitelistingInfoChange({
            ...this.props.whitelistingInfo,
            whitelistedForAll,
        });
    };
    onGroupsWhitelistChange = (groupNames) => {
        this.props.onWhitelistingInfoChange({
            ...this.props.whitelistingInfo,
            vehicleGroupWhitelist: groupNames,
        });
    };
    onGroupsWhitelistInputTextChange = (value) => {
        this.setState({
            groupWhitelistInputText: value,
        });
    };
    onDeviceWhitelistChange = ({ serialNumbers, isValid }) => {
        this.props.onWhitelistingInfoChange({
            ...this.props.whitelistingInfo,
            deviceWhitelist: serialNumbers,
            isValidDeviceWhitelist: isValid,
        });
    };
    onDeviceWhitelistByVINsChange = ({ vinList, isValid }) => {
        this.props.onWhitelistingInfoChange({
            ...this.props.whitelistingInfo,
            vinList: vinList,
            isValidVinList: isValid,
        });
    };
    onGroupsBlacklistChange = (groupNames) => {
        this.props.onWhitelistingInfoChange({
            ...this.props.whitelistingInfo,
            vehicleGroupBlacklist: groupNames,
        });
    };
    onGroupsBlacklistInputTextChange = (value) => {
        this.setState({
            groupBlacklistInputText: value,
        });
    };
    onDeviceBlacklistChange = ({ serialNumbers, isValid }) => {
        this.props.onWhitelistingInfoChange({
            ...this.props.whitelistingInfo,
            deviceBlacklist: serialNumbers,
            isValidDeviceBlacklist: isValid,
        });
    };

    render() {
        const { whitelistingInfo, groups, showWhitelistingByVin } = this.props;
        const { groupWhitelistInputText, groupBlacklistInputText } = this.state;
        const whitelistedForAllInput = this.renderWhitelistedForAllInput();
        const groupWhitelistInput = this.renderGroupInput('groupWhitelist', this.props.groupWhitelistLabel,
            whitelistingInfo.vehicleGroupWhitelist,
            groups, whitelistingInfo.whitelistedForAll, this.onGroupsWhitelistChange, groupWhitelistInputText,
            this.onGroupsWhitelistInputTextChange);
        const deviceWhitelistInput = this.renderDeviceWhitelistInput(whitelistingInfo);
        const deviceWhitelistByVINsInput = this.renderDeviceWhitelistByVINsInput(whitelistingInfo);
        const groupBlacklistInput = this.renderGroupInput('groupBlacklist', this.props.groupBlacklistLabel,
            whitelistingInfo.vehicleGroupBlacklist,
            groups, false, this.onGroupsBlacklistChange, groupBlacklistInputText,
            this.onGroupsBlacklistInputTextChange);
        const deviceBlacklistInput = this.renderDeviceBlacklistInput(whitelistingInfo);



        return (
            <>
                {whitelistedForAllInput}
                {groupWhitelistInput}
                {deviceWhitelistInput}
                {showWhitelistingByVin && deviceWhitelistByVINsInput}
                {groupBlacklistInput}
                {deviceBlacklistInput}
            </>
        );

    }

    renderWhitelistedForAllInput() {
        const { showWhitelistingForAll, enableWhitelistingForAll, whitelistingInfo } = this.props;
        if (showWhitelistingForAll) {
            return (
                <div className='form-group'>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:whitelistedForAll'/>
                    </label>
                    <div>
                        <Checkbox
                            onClick={this.onWhitelistedForAllChange}
                            checked={whitelistingInfo.whitelistedForAll}
                            disabled={!enableWhitelistingForAll}>
                            <FormattedMessage id='intl-msg:whitelistedForAll'/>
                        </Checkbox>
                    </div>
                </div>
            );
        }
    }

    renderGroupInput(name, label, groupNames, groups, disabled, onChange, inputText, onChangeInputText) {
        const optionsWithSelection = [];
        if (!groupNames) {
            groupNames = [];
        }
        forEach(group => {
            if (!groupNames.includes(group.name) && group.name.toUpperCase().startsWith(inputText.toUpperCase())) {
                optionsWithSelection.push({
                    label: group.name,
                });
            }
        }, groups);

        const handleAddSelectedItem = (_, { suggestion }) => {
            onChange([...groupNames, suggestion.label]);
            onChangeInputText('');
        };

        const handleRemoveSelectedItem = tagName => {
            onChange(groupNames.filter(e => e !== tagName));
        };

        const handleSuggestionsChange = ({ value }) => {
            onChangeInputText(value);
        };

        const inputProps = {
            placeholder: 'Start typing ...',
            value: inputText,
        };

        return (
            <div className='form-group'>
                <label className='control-label'>
                    <FormattedMessage id={label}/>
                </label>
                <div className='form-group'>
                    <AutoSuggest
                        inputProps={inputProps}
                        disabled={disabled}
                        suggestions={optionsWithSelection}
                        noItemMessage={<FormattedMessage id='intl-msg:multiSelect.prompt'/>}
                        onSuggestionSelected={handleAddSelectedItem}
                        onSuggestionsFetchRequested={handleSuggestionsChange}
                        openOnFocus
                    />
                    <TagList className={'margin-top-15'}>
                        {groupNames.map(item => (
                            <Tag key={item} deletable onClick={() => handleRemoveSelectedItem(item)}>
                                {item}
                            </Tag>
                        ))}
                    </TagList>
                </div>
            </div>
        );
    }

    renderDeviceWhitelistInput(whitelistingInfo) {
        const { whitelistedForAll, deviceWhitelist } = whitelistingInfo;
        return (
            <SerialNumbersFormItem label={this.props.deviceWhitelistLabel}
                                   serialNumbers={deviceWhitelist}
                                   disabled={whitelistedForAll}
                                   onChange={this.onDeviceWhitelistChange}/>
        );
    }

    renderDeviceWhitelistByVINsInput(whitelistingInfo) {
        const { vinList } = whitelistingInfo;
        return (
            <VINListFormItem label={this.props.vinListLabel}
                             vinList={vinList}
                             onChange={this.onDeviceWhitelistByVINsChange}/>
        );
    }

    renderDeviceBlacklistInput(whitelistingInfo) {
        const { deviceBlacklist } = whitelistingInfo;
        return (
            <SerialNumbersFormItem label={this.props.deviceBlacklistLabel}
                                   serialNumbers={deviceBlacklist}
                                   onChange={this.onDeviceBlacklistChange}/>
        );
    }
}

export default WhitelistingDetailsEditorFormItem;

// TODO Obviously it doesn't work without knowledge about whitelistingInfo
WhitelistingDetailsEditorFormItem.defaultProps = {
    // props
    showWhitelistingForAll: false,
    showWhitelistingByVin: true,
    enableWhitelistingForAll: false,
    whitelistingInfo: {
        deviceWhitelist: [],
        vinList: [],
        compactDeviceWhitelist: [],
    },
    groups: [],
    deviceWhitelistLabel: 'intl-msg:deviceWhitelist',
    vinListLabel: 'intl-msg:includeVinList',
    groupWhitelistLabel: 'intl-msg:groupWhitelist',
    deviceBlacklistLabel: 'intl-msg:deviceBlacklist',
    groupBlacklistLabel: 'intl-msg:groupBlacklist',
    // functions
    onWhitelistingInfoChange: noop,
};

WhitelistingDetailsEditorFormItem.propTypes = {
    // props
    showWhitelistingForAll: PropTypes.bool,
    showWhitelistingByVin: PropTypes.bool,
    enableWhitelistingForAll: PropTypes.bool,
    whitelistingInfo: PropTypes.object,
    groups: PropTypes.arrayOf(Group),
    deviceWhitelistLabel: PropTypes.string,
    vinListLabel: PropTypes.string,
    deviceBlacklistLabel: PropTypes.string,
    groupWhitelistLabel: PropTypes.string,
    groupBlacklistLabel: PropTypes.string,
    deliverableType: PropTypes.string,
    // functions
    onWhitelistingInfoChange: PropTypes.func,
};
