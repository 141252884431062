import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import CM4LogRequest from '~/features/deviceLogs/components/logRequest/CM4LogRequest';
import SetDeviceLogLevel from '~/features/deviceLogs/components/logLevel/SetDeviceLogLevel';
import CM4LogsTable from '~/features/deviceLogs/components/logsTable/CM4LogsTable';

export class DeviceLogs extends PureComponent {
    onDownloadLogFile = (logFile) => {
        this.props.downloadDeviceLogFile(this.props.serialNumber, logFile);
    };

    getLogRequestComponentByDeviceType = ( serialNumber) => {
        const {
            isDeviceLogsUser,
        } = this.props;

            return <CM4LogRequest serialNumber={serialNumber}
                                  isDeviceLogsUser={isDeviceLogsUser}
                    />;
    };

    getLogListByDeviceType = (deviceType, serialNumber) => {
            return <CM4LogsTable serialNumber={serialNumber} deviceType={deviceType}/>;
    };

    render() {
        const {
             controlDevice, canCreateDeviceActions, isDeviceLogsUser,
        } = this.props;

        return (
            <div style={{ backgroundColor: '#F5F5F5' }} className={'padding-bottom-10'}>
                <div className={'display-flex flex-column'}>
                    <div className='rounded-large bg-white margin-top-10 margin-left-10 margin-right-10'>
                        <SetDeviceLogLevel serialNumber={controlDevice.originalSerialNumber}
                                           deviceType={controlDevice.type}
                                           isDeviceLogsUser={isDeviceLogsUser}
                                           canCreateDeviceActions={canCreateDeviceActions}
                        />
                    </div>
                    <div className='rounded-large bg-white margin-top-10 margin-left-10 margin-right-10'>
                        {this.getLogRequestComponentByDeviceType(controlDevice.type, controlDevice.originalSerialNumber)}
                    </div>
                </div>

                <div
                    className={'rounded-large bg-white margin-top-10  margin-left-10 margin-right-10 padding-20'}>
                    {this.getLogListByDeviceType(controlDevice.type, controlDevice.originalSerialNumber)}
                </div>
            </div>)
            ;
    }
}

export default DeviceLogs;

DeviceLogs.defaultProps = {
    // props
    canCreateDeviceActions: false,
    canEditDeviceActions: false,
    isDeviceLogsUser: false,
    deviceLog: [],
    // functions
    showDeviceActionEditor: noop,
    downloadDeviceLogFile: noop,

};

DeviceLogs.propTypes = {
    // props
    canCreateDeviceActions: PropTypes.bool,
    canEditDeviceActions: PropTypes.bool,
    isDeviceLogsUser: PropTypes.bool,
    serialNumber: PropTypes.string,
    deviceAction: PropTypes.object,
    deviceLog: PropTypes.array,
    // functions
    showDeviceActionEditor: PropTypes.func,
    downloadDeviceLogFile: PropTypes.func,
};
