import getOr from 'lodash/fp/getOr';

import { ENTITY_USER } from '~/features/base/constants/entities';

import { entityStoreSelector } from '~/features/higherorder/selectors/dataSelectors';
import { ROLE_TOTAL_OTA_ADMIN, ROLE_TOTAL_OTA_USER } from '~/features/base/constants/permissions';

const userDataSelector = state => entityStoreSelector(state)[ENTITY_USER];

export const permissionsFetchedSelector = (state) =>
    !!userDataSelector(state);

export const rolesSelector = (state) =>
    getOr([], 'otaRoles', userDataSelector(state));

export const otherRolesSelector = (state) =>
    getOr([], 'otherRoles', userDataSelector(state));

export const permissionsSelector = (state) =>
    getOr([], 'permissions', userDataSelector(state));

export const hasSufficientRolesSelector = (state) =>
    rolesSelector(state).includes(ROLE_TOTAL_OTA_USER) ||
    rolesSelector(state).includes(ROLE_TOTAL_OTA_ADMIN);

export const isDiagLogFileManagerSelector = (state) =>
    rolesSelector(state).includes(ROLE_TOTAL_OTA_ADMIN)

export const canManageDeliverablesSelector = (state) =>
{
   return  (rolesSelector(state).includes(ROLE_TOTAL_OTA_ADMIN))
}

export const canFullyManageOwnerContextSelector = (state) =>
    rolesSelector(state).includes(ROLE_TOTAL_OTA_ADMIN);

export const isDeviceMonitorUserSelector = (state) =>
    otherRolesSelector(state).includes(ROLE_TOTAL_OTA_ADMIN);

export const canTriggerCheck4UpdateSelector = (state) =>
    rolesSelector(state).includes(ROLE_TOTAL_OTA_ADMIN);
export const isTestManager = (state) =>
    rolesSelector(state).includes(ROLE_TOTAL_OTA_ADMIN);
