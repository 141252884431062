import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { useSetLogLevelMutation } from '~/api/logs/LoggingService.api';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { createLogLevelFilterOptions } from '~/features/deviceLogs/constants/logLevelOptions';
import { LogLevelRequestDto } from '~/api/models/LogLevelRequestDto';
import isArray from 'lodash/fp/isArray';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { CM4 } from '~/features/devices/constants/deviceTypes';
import { createCM4SystemFilterOptions } from '~/features/base/constants/serviceOptionsCM4';
import SelectOTA from '~/features/base/components/forms/SelectOTA';
import AutoSuggestOTA from '~/features/base/components/forms/AutoSuggestOTA';

interface SetDeviceLogLevelProps {
    serialNumber: string;
    isDeviceLogsUser: boolean;
    canCreateDeviceActions: boolean;
    deviceType: LogLevelRequestDto.DeviceTypeEnum;
}

const SetDeviceLogLevel: React.FC<SetDeviceLogLevelProps> = ({ serialNumber, isDeviceLogsUser, deviceType, canCreateDeviceActions }) => {

    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        reset,
    } = useForm();

    const [logLevelOptions] = useState(createLogLevelFilterOptions());
    const serviceOptions = createCM4SystemFilterOptions();
    const [suggestions, setsSuggestions] = useState([]);

    const [setLogLevel] = useSetLogLevelMutation();
    const buttonClassName = { disabled: !isDeviceLogsUser };

    const setLogLevelService = {
        [CM4]: setLogLevel,
    };

    const handleRequest = async(requestParams: LogLevelRequestDto) => {

        await setLogLevelService[deviceType](requestParams)
            .unwrap()
            .then(() => {
                Notification.success(<FormattedMessage
                    id='intl-msg:logs.logLevelSuccessMsg'/>);
                reset();
            })
            .catch((error) => {
                console.error(error);
                Notification.error(<FormattedMessage id='intl-msg:logs.logLevelErrorMsg'/>);
            });

    };

    const onSubmit = data => {
        const requestParams: LogLevelRequestDto = {
            deviceType,
            serialNumber: serialNumber,
            level: data.logLevel.id,
            applicationName: data.applicationName,
        };
        handleRequest(requestParams);
    };

    const handleSuggestionsFetchRequested = ({value}) => {
        const newValue =  Array.isArray(value) ? value.join('') : value;
        setValue('applicationName', newValue);
        setsSuggestions(serviceOptions?.filter(service => service?.id.includes(newValue)));
    };

    const onServiceChange = (event, value) => {
        const id = isArray(value.newValue) ? value.newValue[0] : value.newValue;
        setValue('applicationName', id);
    };

    const onClear = () => {
        setValue('applicationName', '');
    }

    const servicePlaceholder = 'Select Service...';
    const logLevelPlaceholder = <FormattedMessage id='intl-msg:logs.logLevelPlaceholder'/>;

    // @ts-ignore
    return (
        <ExpanderPanel title={
            <div>
                <span className={'h5'}>
                    <FormattedMessage id='intl-msg:editDeviceServiceLogLevel'/>
                </span>
                <OverlayTrigger
                        key={1}
                        placement={'right-start'}
                        overlay={
                            <Tooltip id='tooltip' allowOnTouch>
                                <strong><FormattedMessage id='intl-msg:headsUp'/></strong>
                                <p><FormattedMessage id='intl-msg:logLevelChange.phrase'/></p>
                                <p><FormattedMessage id='intl-msg:logLevelChange.serviceNameHint'/></p>
                            </Tooltip>
                        }
                    >
                    <span className="rioglyph rioglyph-info-sign margin-left-10 label-info" style={{fontSize: '18px'}} aria-hidden="true"></span>
                </OverlayTrigger>
            </div>}
                       bsStyle='blank'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={'display-flex justify-content-around'}>
                    <div className={'width-50pct margin-right-20'}>
                        <label className='control-label'>
                            <FormattedMessage id='intl-msg:service'/>
                        </label>
                        <Controller
                            control={control}
                            name='applicationName'
                            rules={{ required: true }}
                            render={({ field : {onChange, value}}) =>
                                <>
                                    <AutoSuggestOTA
                                        hasError={!!errors.applicationName}
                                        suggestions={suggestions}
                                        inputProps={{
                                            value,
                                            onChange: onServiceChange,
                                            onClear,
                                            placeholder: servicePlaceholder
                                        }}
                                        onSuggestionSelected={()=>{}}
                                        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                                    />
                                </>
                            }
                        />
                    </div>
                    <div className={'width-50pct'}>
                        <label className='control-label'>
                            <FormattedMessage id='intl-msg:logLevel'/>
                        </label>
                        <Controller
                            control={control}
                            name='logLevel'
                            rules={{ required: true }}
                            render={({ field : {onChange, value}}) =>
                                <>
                                    <SelectOTA
                                        hasError={!!errors.logLevel}
                                        placeholder={logLevelPlaceholder}
                                        options={logLevelOptions}
                                        value={value?.id}
                                        onChange={onChange}
                                    />
                                </>
                            }
                        />
                    </div>
                </div>
                <div className={'margin-top-20 display-flex justify-content-end align-items-end'}>
                    <button type={'submit'} className={classNames('btn btn-primary', buttonClassName)} onClick={()=>{}}>
                        <FormattedMessage id='intl-msg:editDeviceServiceLogLevel'/>
                    </button>
                </div>
            </form>
        </ExpanderPanel>
    );
};

export default SetDeviceLogLevel;
