import flow from 'lodash/fp/flow';
import omit from 'lodash/fp/omit';
import pick from 'lodash/fp/pick';
import size from 'lodash/fp/size';
import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { hideModal } from '~/features/base/actions/ui/modalsActions';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import {
    ENTITY_BASE_SW_VERSIONS,
    ENTITY_DELIVERABLE,
    ENTITY_DELIVERABLE_ID,
    ENTITY_DELIVERABLES_CONTEXT_INFO,
    ENTITY_DELIVERABLES_USER_INFO,
    ENTITY_LIVE_BUNDLES,
    ENTITY_LIVE_CM4GS,
} from '~/features/base/constants/entities';
import { deleteHTTP, doHandleErrorSaga, getHTTP, postHTTP, putHTTP } from '~/features/base/sagas/sagaUtil';
import {
    getDeliverableManagementBackend,
    getDeviceManagementBackend,
} from '~/features/base/selectors/backendSelectors';
import { stringifyToQuery } from '~/features/base/utils/query';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import {
    ADD_NEW_CONTEXT,
    CREATE_DELIVERABLE,
    DELETE_DELIVERABLE,
    DELETE_DELIVERABLE_VERSION,
    deleteDeliverableVersion,
    FETCH_ALL_DELIVERABLE_IDS,
    FETCH_DELIVERABLES_USER_INFO,
    FETCH_FILTERED_DELIVERABLE_IDS,
    FETCH_FILTERED_DELIVERABLES,
    FETCH_LIVE_DELIVERABLES,
    fetchDeliverablesUserInfo,
    fetchFilteredDeliverables,
    setDeliverables,
    UPDATE_DELIVERABLE,
} from '~/features/deliverables/actions/deliverableActions';
import {
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_FILE,
    FETCH_DELIVERABLES_AMOUNT_INCREMENT,
} from '~/features/deliverables/constants/deliverablesParameters';
import { getDeliverableId, getDeliverableTypePath, isWhitelistEmpty } from '~/features/deliverables/utils/utils';
import { mergeAggregation } from '~/features/higherorder/actions/aggregationActions';
import { deleteEntity, mergeEntity, resetEntity } from '~/features/higherorder/actions/entityActions';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';
import { failedAction, finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { profileSelector } from '~/features/login/selectors';
import { filterEmptySoftwareDependencies } from '~/features/base/utils/softwareCompatibilityUtils';
import { doFetchFilteredControlDevicesSaga } from '~/features/devices/sagas/controlDeviceSaga';
import {
    FETCH_DELIVERABLE_WHITELISTING,
    fetchFilteredControlDevices,
} from '~/features/devices/actions/controlDeviceActions';
import { DELIVERABLES_MANAGEMENT_PATH } from '~/features/base/constants/routes';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import { filterEmptyDependencies } from '~/features/deliverables/utils/checkEmptyDeliverableDependecies';
import { removeEmpty } from '~/features/base/utils/removeEmpty';

const DEFAULT_SIZE = 200;

export function* getDeliverablesURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/deliverable`;
}

export function* getDeliverablesContextURL() {
    const serviceURL = yield select(getDeliverableManagementBackend);
    return `${serviceURL}/context/v1`;
}

export function* getDeliverablesUserInfoURL() {
    const serviceURL = yield select(getDeliverableManagementBackend);
    return `${serviceURL}/user/v1`;
}

export function* getDeliverableRolloutsURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/deliverable/rollouts-overview`;
}

export function* mergeFilteredDeliverablesSaga(response, scope) {
    yield put(mergeEntity(response.content, { entityName: ENTITY_DELIVERABLE }));
}

export function* mergeBaseSwVersionsSaga(response) {
    yield put(mergeEntity(response, { entityName: ENTITY_BASE_SW_VERSIONS }));
}

export function* doFetchFilteredDeliverablesSaga(action) {
    yield put(startLoading(ENTITY_DELIVERABLE));
    try {
        yield put(deleteEntity({}, { entityName: ENTITY_DELIVERABLE }));
        const url = yield call(getDeliverablesURL)
        let searchCriteria = {
            ...action.searchCriteria,
            deliverableType: action.searchCriteria.deliverableType.toUpperCase(),
        };
        if (searchCriteria.deliverableIdPrefix) {
            searchCriteria = omit(['deliverableId'], searchCriteria);
        }
        if (searchCriteria.deliverableIdPrefix === '') {
            searchCriteria = omit(['deliverableIdPrefix'], searchCriteria);
        }
        action.size = !action.size ? DEFAULT_SIZE : action.size;
        const query = flow(pick(['page', 'size']), stringifyToQuery)(action);
        const response = yield call(postHTTP, `${url}/search?${query}`,
            JSON.stringify(searchCriteria));
        if (size(response.content) > 0) {
            yield call(mergeFilteredDeliverablesSaga, response, action.scope);
            if (action.searchCriteria.deliverableType === 'distro') {
                const allBaseSoftware = response.content.map(
                    d => toShortSemanticVersion(d.distroInfo.baseSoftwareVersion));
                const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
                const baseSoftwareVersions = [...new Set(allBaseSoftware)].sort(collator.compare).reverse();
                yield call(mergeBaseSwVersionsSaga, baseSoftwareVersions);
            }
        } else {
            yield put(deleteEntity(response, { entityName: ENTITY_DELIVERABLE }));
        }
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_DELIVERABLE));
    yield put(finishedAction(action.type));
}

export function* mergeFilteredDeliverableIdsSaga(response, scope) {
    yield put(mergeEntity(response.content, { entityName: ENTITY_DELIVERABLE_ID }));
}

export function* doFetchFilteredDeliverableIdsSaga(action) {
    action.page = Number.isNaN(Number(action.page)) ? 0 : Number(action.page);
    action.size = Number.isNaN(Number(action.size)) ? FETCH_DELIVERABLES_AMOUNT_INCREMENT : Number(action.size);
    yield put(startLoading(ENTITY_DELIVERABLE_ID));
    try {
        const urlNeo = yield call(getDeliverablesURL);
        const { deliverableType } = action.searchCriteria;
        let { deliverableId } = action.searchCriteria;
        let searchCriteria = {
            ...action.searchCriteria,
            deliverableType: deliverableType.toUpperCase(),
        };
        if (deliverableId) {
            searchCriteria = omit(['deliverableId'], searchCriteria);
        }
        action.size = !action.size ? DEFAULT_SIZE : action.size;
        const query = flow(
            pick(['page', 'size', 'releaseState', 'context', 'deliverableIdPrefix']),
            stringifyToQuery)(action);
        const response = yield call(postHTTP, `${urlNeo}/search/deliverable-ids?${query}`,
            JSON.stringify(searchCriteria));

        yield call(mergeFilteredDeliverableIdsSaga, response, action.scope);
        yield put(mergeAggregation({ entityName: ENTITY_DELIVERABLE_ID, scope: 'totalCount' }, response.totalElements));
        yield put(mergeEntity({ entityName: ENTITY_DELIVERABLE_ID, scope: 'totalCount' }, response.totalElements));

        if (response.content.length === 0) {
            yield put(resetEntity({}, { entityName: ENTITY_DELIVERABLE }));
        }
        deliverableId = getDeliverableId(response.content, deliverableId);
        if (deliverableId) {
            yield call(doFetchFilteredDeliverablesSaga, fetchFilteredDeliverables({
                page: Number(action.page),
                size: Number(action.size),
                searchCriteria: {
                    // deliverableIdPrefix: deliverableId,
                    owningContext: action.searchCriteria.owningContext,
                    releaseStates: action.searchCriteria.releaseStates,
                    deliverableType,
                    deliverableId,
                },
            }));
        } else {
            yield put(endLoading(ENTITY_DELIVERABLE));
        }
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_DELIVERABLE));

    yield put(endLoading(ENTITY_DELIVERABLE_ID));
    yield put(finishedAction(action.type));
}

export function* doFetchAllDeliverableIdsSaga(action) {
    yield put(startLoading(ENTITY_DELIVERABLE_ID));
    try {
        const urlNeo = yield call(getDeliverablesURL);
        const { deliverableType } = action.searchCriteria;

        const payload = {
            deliverableType: deliverableType.toUpperCase(),
        };

        action.size = !action.size ? DEFAULT_SIZE : action.size;
        const query = flow(
            pick(['page', 'size']),
            stringifyToQuery)(action);
        const response = yield call(postHTTP, `${urlNeo}/search/deliverable-ids?${query}`, JSON.stringify(payload));

        yield put(setDeliverables(deliverableType, response.content));

    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_DELIVERABLE_ID));
    yield put(finishedAction(action.type));
}

export function* doFetchDeliverablesUserInfoSaga(action) {
    yield put(startLoading(ENTITY_DELIVERABLES_USER_INFO));
    try {
        const url = yield call(getDeliverablesUserInfoURL);
        //todo:Fix this request
       // const response = yield call(getHTTP, `${url}/current`);
        //yield put(mergeEntity(response, { entityName: ENTITY_DELIVERABLES_USER_INFO }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_DELIVERABLES_USER_INFO));
    yield put(finishedAction(action.type));
}

function addDeliverableSequence(payload) {
    if (payload.bundleDeliverables) {
        payload.bundleDeliverables = payload.bundleDeliverables.map((deliverable, index) => ({
            ...deliverable,
            sequence: index,
        }));
    }
}

export function prepareDeliverablePayload(payload) {
    let filteredPayload = filterPayloadbyDeliverableType(JSON.parse(JSON.stringify(payload)));
    filterEmptySoftwareDependencies(filteredPayload.compatibility);
    filterEmptyDependencies(filteredPayload?.dependencies?.deliverables);
    filteredPayload = removeEmpty(JSON.parse(JSON.stringify(filteredPayload)));
    addDeliverableSequence(filteredPayload);
    return filteredPayload;
}

function filterPayloadbyDeliverableType(payload) {
    // filter deliverable type specific
    let filteredPayload = payload;
    const deliverableType = payload.deliverableType;

    switch (deliverableType.toLowerCase()) {
        case DELIVERABLE_TYPE_FILE:
            filteredPayload = omit(['appInfo', 'distroInfo', 'bundleInfo', 'bundleDeliverables'], filteredPayload);
            break;
        case DELIVERABLE_TYPE_CM4G:
            filteredPayload = omit(['appInfo', 'fileInfo', 'distroInfo', 'compatibility', 'bundleInfo', 'bundleDeliverables'], filteredPayload);
            break;
        case DELIVERABLE_TYPE_BUNDLE:
            filteredPayload = omit(['appInfo', 'fileInfo', 'distroInfo', 'compatibility',
                'downloadUrl'], filteredPayload);
            const downloadUrl = 'https://dummy/'; //not necessary for bundles but is not nullable in the DB
            filteredPayload = { ...filteredPayload, downloadUrl };
            break;
        default:
    }

    // remove FE validation fields
    filteredPayload = omit(['whitelisting.isValidDeviceWhitelist', 'whitelisting.isValidDeviceBlacklist'], filteredPayload);

    return {
        ...filteredPayload,
        deliverableType: deliverableType.toUpperCase(),
    };
}

export function* doCreateDeliverableSaga(action) {
    try {
        const url = yield call(getDeliverablesURL);
        const filteredPayload = prepareDeliverablePayload(action.payload);
        const response = yield call(postHTTP, url, JSON.stringify(filteredPayload));
        const { deliverableType, deliverableId, owningContext } = response;
        yield put(fetchFilteredDeliverables(
            {
                page: 0,
                searchCriteria: {
                    owningContext,
                    deliverableType,
                    deliverableId,
                },
            },
        ));
        yield put(triggerDataFetcher());
        yield put(followRoute({
            route: `/${DELIVERABLES_MANAGEMENT_PATH}/${getDeliverableTypePath(deliverableType.toLowerCase())}/${deliverableId}/${toShortSemanticVersion(action.payload.deliverableVersion)}`,
        }));
        yield put(hideModal());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doUpdateDeliverableSaga(action) {
    try {
        const url = yield call(getDeliverablesURL);
        const filteredPayload = prepareDeliverablePayload(action.payload);
        const deliverable = yield call(putHTTP, `${url}`, JSON.stringify(filteredPayload));
        const { deliverableType, deliverableId, owningContext } = deliverable;
        yield put(fetchFilteredDeliverables(
            {
                page: 0,
                searchCriteria: {
                    owningContext,
                    deliverableType,
                    deliverableId,
                },
            },
        ));
        yield put(triggerDataFetcher());
        yield put(hideModal());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doDeleteDeliverableSaga(action) {
    try {
        const deliverableId = action.payload.deliverableId;
        const deliverableType = action.payload.deliverableType;
        const deliverableVersions = action.payload.deliverableVersions;

        // get all versions and delete for each version
        for (const deliverableVersion of deliverableVersions) {
            yield put(deleteDeliverableVersion(deliverableId, deliverableType, deliverableVersion));
        }
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doDeleteDeliverableVersionSaga(action) {
    try {
        const url = yield call(getDeliverablesURL);
        const deliverableId = action.payload.deliverableId;
        const deliverableType = action.payload.deliverableType;
        const deliverableVersion = action.payload.deliverableVersion;
        yield call(deleteHTTP, `${url}/${deliverableType.toUpperCase()}/${deliverableId}/${deliverableVersion}`);
        yield put(hideModal());
        yield put(triggerDataFetcher());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doAddNewContextSaga(action) {
    yield put(startLoading(ENTITY_DELIVERABLES_CONTEXT_INFO));
    const userProfile = yield select(profileSelector);
    try {
        const url = yield call(getDeliverablesContextURL);
        const payload = {
            name: action.newContextName,
            owners: [
                userProfile.unique_name,
            ],
        };
        yield call(postHTTP, url, JSON.stringify(payload));
        yield put(fetchDeliverablesUserInfo());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_DELIVERABLES_CONTEXT_INFO));
    yield put(finishedAction(action.type));
}

const liveRolloutEntityByDeliverable = {
    [DELIVERABLE_TYPE_CM4G]: ENTITY_LIVE_CM4GS,
    [DELIVERABLE_TYPE_BUNDLE]: ENTITY_LIVE_BUNDLES,

};

export function* doFetchLiveDeliverablesSaga(action) {
    const entity = liveRolloutEntityByDeliverable[action.payload.type.toLowerCase()];
    yield put(startLoading(entity));
    try {
        const deliverableRolloutURL = yield call(getDeliverableRolloutsURL);
        const query = flow(pick(['type']), stringifyToQuery)(action.payload);
        const { rollouts } = yield call(getHTTP, `${deliverableRolloutURL}?${query}`);
        yield put(mergeEntity(rollouts, { entityName: entity }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(entity));
    yield put(finishedAction(action.type));
}

export function* doFetchDeliverableWhitelistingSaga(action) {
    const { whitelisting, page, size } = action.payload;
    if (!isWhitelistEmpty(whitelisting)) {
        yield call(doFetchFilteredControlDevicesSaga, fetchFilteredControlDevices({
            page,
            size,
            searchCriteria: {
                whitelistingInfo: whitelisting,
            },
        }));
    }
    yield put(finishedAction(action.type));
}

export function* fetchDeliverableWhitelistingSaga() {
    yield takeLatest(FETCH_DELIVERABLE_WHITELISTING, doFetchDeliverableWhitelistingSaga);
}

export function* fetchFilteredDeliverablesSagaNeo() {
    yield takeLatest(FETCH_FILTERED_DELIVERABLES, doFetchFilteredDeliverablesSaga);
}

export function* fetchFilteredDeliverableIdsSagaNeo() {
    yield takeLatest(FETCH_FILTERED_DELIVERABLE_IDS, doFetchFilteredDeliverableIdsSaga);
}

export function* fetchAllDeliverableIdsSagaNeo() {
    yield takeEvery(FETCH_ALL_DELIVERABLE_IDS, doFetchAllDeliverableIdsSaga);
}

export function* fetchDeliverablesUserInfoSagaNeo() {
    yield takeLatest(FETCH_DELIVERABLES_USER_INFO, doFetchDeliverablesUserInfoSaga);
}

export function* createDeliverableSagaNeo() {
    yield takeEvery(CREATE_DELIVERABLE, doCreateDeliverableSaga);
}

export function* updateDeliverableSaga() {
    yield takeEvery(UPDATE_DELIVERABLE, doUpdateDeliverableSaga);
}

export function* deleteDeliverableSagaNeo() {
    yield takeEvery(DELETE_DELIVERABLE, doDeleteDeliverableSaga);
}

export function* deleteDeliverableVersionSagaNeo() {
    yield takeEvery(DELETE_DELIVERABLE_VERSION, doDeleteDeliverableVersionSaga);
}

export function* addNewContextSagaNeo() {
    yield takeLatest(ADD_NEW_CONTEXT, doAddNewContextSaga);
}

export function* fetchLiveDeliverablesSaga() {
    yield takeEvery(FETCH_LIVE_DELIVERABLES, doFetchLiveDeliverablesSaga);
}

