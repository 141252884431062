import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { DELIVERABLES_PATH } from '~/features/base/constants/routes';
import { DELIVERABLE_TYPE_FILE } from '~/features/deliverables/constants/deliverablesParameters';
import noop from 'lodash/fp/noop';
import {
    DEVICE_QUICK_WHITELISTING_MODE_INSTALL,
} from '~/features/devices/components/dialogs/quickWhitelisting/DeviceQuickWhitelistingDialog';
import { CM4 } from '~/features/devices/constants/deviceTypes';
import { DeliverableType } from '~/features/deliverables/constants/DeliverableType';

export class DeliverableTypeTabs extends PureComponent {
    render() {
        const navigation = this.renderNavigation();
        return (
            <div className='file-details-tabs row'>
                <div className='col-md-12'>
                    {navigation}
                </div>
            </div>
        );
    }

    renderNavigationItem(view, label, selectedDeliveryType) {
        const className = {
            active: selectedDeliveryType === view,
        };
        return (
            <li key={view} className={classNames(className)} onClick={() => this.props.onDeliveryTypeChange(view)}>
                <span><FormattedMessage id={label}/></span>
            </li>
        );
    }

    renderNavigation() {
        const { mode, selectedDeliveryType, deviceType } = this.props;
        return (
            <ul className='nav nav-tabs user-select-none'>
                {deviceType === CM4 && this.renderNavigationItem(DeliverableType.CM4G, DeliverableType.CM4G, selectedDeliveryType)}
                {mode === DEVICE_QUICK_WHITELISTING_MODE_INSTALL && this.renderNavigationItem(DELIVERABLE_TYPE_FILE,
                    'intl-msg:files', selectedDeliveryType)}
            </ul>
        );
    }
}

export default DeliverableTypeTabs;

DeliverableTypeTabs.defaultProps = {
    // props
    mode: '',
    view: DELIVERABLES_PATH,
    selectedDeliveryType: '',
    // functions
    onDeliveryTypeChange: noop,
};

DeliverableTypeTabs.propTypes = {
    // props
    mode: PropTypes.string,
    view: PropTypes.string,
    selectedDeliveryType: PropTypes.string,
    // functions
    onDeliveryTypeChange: PropTypes.func,
};
