export const PERMISSION_PACKAGE_READ = 'package.read';

export const PERMISSION_PACKAGE_DELIVERABLE_READ = 'packageDeliverable.read';

export const PERMISSION_FILE_READ = 'file.read';

export const PERMISSION_FILE_DELIVERABLE_READ = 'fileDeliverable.read';

export const DELIVERABLE_USER_INFO_PERMISSIONS = 'permissions';
export const PERMISSION_DELIVERABLE_FILE_READ = 'file.read';
export const PERMISSION_DELIVERABLE_FILE_WRITE = 'file.write';
export const PERMISSION_DELIVERABLE_FILE_DELETE = 'file.delete';

export const ARTIFACT_USER_INFO_PERMISSIONS = 'permissions';
export const PERMISSION_ARTIFACT_FILE_READ = 'file.read';
export const PERMISSION_ARTIFACT_FILE_WRITE = 'file.write';
export const PERMISSION_ARTIFACT_FILE_DELETE = 'file.delete';

export const PERMISSION_CONTROL_DEVICE_CREATE = 'controlDevice.create';
export const PERMISSION_CONTROL_DEVICE_READ = 'controlDevice.read';
export const PERMISSION_CONTROL_DEVICE_UPDATE = 'controlDevice.update';
export const PERMISSION_CONTROL_DEVICE_DELETE = 'controlDevice.delete';


export const PERMISSION_DEVICE_ACTION_CREATE = 'controlDevice.read';
export const PERMISSION_DEVICE_ACTION_READ = 'controlDevice.read';
export const PERMISSION_DEVICE_ACTION_UPDATE = 'controlDevice.read';
export const PERMISSION_DEVICE_ACTION_DELETE = 'controlDevice.read';


export const PERMISSION_CM4G_DELIVERABLE_CREATE = 'deliverable.create';
export const PERMISSION_CM4G_DELIVERABLE_READ = 'deliverable.read';
export const PERMISSION_CM4G_DELIVERABLE_UPDATE = 'deliverable.update';
export const PERMISSION_CM4G_DELIVERABLE_DELETE = 'deliverable.delete';


export const PERMISSION_UPDATE_HISTORY_READ = 'updateHistory.read';

export const readPermissions = [
    PERMISSION_PACKAGE_READ,
    PERMISSION_PACKAGE_DELIVERABLE_READ,
    PERMISSION_FILE_READ,
    PERMISSION_FILE_DELIVERABLE_READ,
    PERMISSION_CONTROL_DEVICE_READ,
    PERMISSION_DEVICE_ACTION_READ,
    PERMISSION_CM4G_DELIVERABLE_READ,
];

// New Roles
export const ROLE_TOTAL_OTA_ADMIN = `total.ota.admin`;
export const ROLE_TOTAL_OTA_USER = `total.ota.user`;
export const ROLE_TOTAL_OTA_MONITORING = `total.ota.user`;
