import { RESET_DELIVERABLES, SET_DELIVERABLES } from '~/features/deliverables/actions/deliverableActions';
import {
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';

const defaultState = {
    file: [],
    cm4g: [],
    bundle: [],
};

const resetDeliverables = () => {
    return defaultState;
};

const setDeliverables = (state, action) => {
    const deliverables = action.deliverables;
    switch (action.deliverableType) {
        case DELIVERABLE_TYPE_FILE:
            return {
                ...state,
                file: deliverables,
            };
        case DELIVERABLE_TYPE_CM4G:
            return {
                ...state,
                cm4g: deliverables,
            };
        case DELIVERABLE_TYPE_BUNDLE:
            return {
                ...state,
                bundle: deliverables,
            };
        default:
            return state;
    }
};

export default function deliverableReducerNeo(state = defaultState, action = {}) {
    switch (action.type) {
        case SET_DELIVERABLES:
            return setDeliverables(state, action);
        case RESET_DELIVERABLES:
            return resetDeliverables(state, action);
        default:
            return state;
    }
}
