import React from 'react';

import {
    DELIVERABLES_BUNDLES_PATH,
    DELIVERABLES_CM4G_PATH,
    DELIVERABLES_FILES_PATH,
    DELIVERABLES_PATH
} from '~/features/base/constants/routes';

import Chip from '~/features/base/components/Chip';
import { openInNewTab } from '~/features/base/utils/openInNewTab';
import classNames from 'classnames';
import { DeliverableType } from '~/features/deliverables/constants/DeliverableType';

interface DeliverableChipProps {
    deliverableId: string;
    bsStyle?: string;
    className?: string;
    deliverableVersion: any;
    deliverableType: string;
    displayValue?: string;
    withTooltip?: boolean;
}

const deliverableSettings = {
    [DeliverableType.CM4G]: {
        path: DELIVERABLES_CM4G_PATH,
        tooltip: 'intl-msg:cm4gVersions'
    },
    [DeliverableType.BUNDLE]: {
        path: DELIVERABLES_BUNDLES_PATH,
        tooltip: 'intl-msg:bundleVersion'
    },
    [DeliverableType.FILE]: {
        path: DELIVERABLES_FILES_PATH,
        tooltip: 'intl-msg:fileVersion'
    }
};

export const DeliverableChip = ({
    deliverableId,
    bsStyle,
    className,
    deliverableVersion,
    deliverableType,
    displayValue
}: DeliverableChipProps) => {
    const handleOnClick = () => openInNewTab(`/#${DELIVERABLES_PATH}/${deliverableSettings[deliverableType.toUpperCase()].path}?deliverableIdPrefix=${deliverableId}&deliverableVersion=${deliverableVersion}`);
    return (
        <Chip onClick={handleOnClick} bsStyle={bsStyle} className={classNames('clickable', className)}
              toolTipMessage={deliverableSettings[deliverableType.toUpperCase()]?.tooltip}>
            {displayValue ? displayValue : deliverableVersion}
        </Chip>
    );
};
export default DeliverableChip;
