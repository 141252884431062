import { connect } from 'react-redux';
import { followRoute } from '~/features/base/actions/ui/routeActions';

import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';

import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import ArtifactList from '~/features/deliverables/features/artifacts/components/ArtifactList';
import {
    showArtifactDeletionDialog,
    showArtifactEditorDialog,
} from '~/features/deliverables/features/artifacts/actions/artifactActions';
import { deliverablesLoadingSelector } from '~/features/deliverables/selectors/deliverableSelectors';

export const mapStateToProps = (state) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        deliverablesLoading: deliverablesLoadingSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        onCreateArtifact: (options) => {
            dispatch(showArtifactEditorDialog(options));
        },
        onEditArtifact: (options) => {
            dispatch(showArtifactEditorDialog(options));
        },
        onDeleteArtifact: (options) => {
            dispatch(showArtifactDeletionDialog(options));
        },
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtifactList);
