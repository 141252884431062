/* eslint-disable camelcase, no-console */
import {InMemoryWebStorage, User, UserManager, WebStorageStateStore} from 'oidc-client-ts';
import join from 'lodash/fp/join';
import getOr from 'lodash/fp/getOr';
import {mapUserProfile} from './userProfile';
import {env} from '~/env';
import {trace} from "~/setup/trace";

const {runtimeConfig} = env;

const pullLocale = getOr('en-GB', 'profile.locale');

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (oauthConfig, userManager) => {
    userManager.signinSilent().catch(error => {
        trace('oidc.signinSilent failed!', error);
        if (error.message === 'login_required') {
            trace('oidc.sessionExpired');
            oauthConfig.onSessionExpired();
        } else {
            trace('oidc.schedulingRetry');
            setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
        }
    });
};

export const adaptPublishedInfo = (result = {}) => ({
    accessToken: result.access_token,
    expiresInSeconds: result.expires_in,
    idToken: result.profile,
    locale: pullLocale(result),
    profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
    const redirectUri = runtimeConfig.login.redirectUri;
    const silentRedirectUri = runtimeConfig.login.silentRedirectUri;
    const resource = runtimeConfig.login.resource;

    const settings = {
        authority: `${runtimeConfig.login.authority}`,
        client_id: `${runtimeConfig.login.clientId}`,
        loadUserInfo: false,
        redirect_uri: `${redirectUri}`,
        response_type: `code`,
        scope: join(' ', runtimeConfig.login.oauthScope),
        silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
        includeIdTokenInSilentRenew: false,
        automaticSilentRenew: true,
        staleStateAge: 600,
        userStore: new WebStorageStateStore({store: new InMemoryWebStorage()}),
    };

    if(resource)

    trace('oidc.auth.settings', settings);

    return new UserManager(settings);
};

export const configureUserManager = (oauthConfig, userManager) => {
    userManager.events.addUserLoaded(user => {
        trace('oidc.signinSilent success!', user);
        oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
    });

    userManager.events.addUserUnloaded(() => {
        trace('oidc.sessionExpired!');
        oauthConfig.onSessionExpired();
    });

    userManager.events.addSilentRenewError(error => {
        trace('oidc.silentRenewError', error);
        retrySigninSilent(oauthConfig, userManager);
    });

    userManager.events.addUserSignedOut((...args) => {
        trace('oidc.userSignedOut', ...args);
        oauthConfig.onSessionExpired();
    });

    userManager.events.addAccessTokenExpired((...args) => {
        trace('oidc.accessTokenExpired', ...args);
        oauthConfig.onSessionExpired();
    })

    userManager.events.addAccessTokenExpiring((...args) => {
        trace('oidc.accessTokenExpiring', ...args);
        retrySigninSilent(oauthConfig, userManager);
    });

    return userManager;
};

export const configureMockUserManager = oauthConfig => {
    console.warn('[configuration/login/oidc-session] Using mocked authorization due to config setting');

    const signinSilent = () => {
        const userSettings = {
            access_token: 'valid-mocked-oauth-bogus-token',
            profile: {
                iss: 'Issuer Identifier',
                aud: 'Audience(s): client_id',
                exp: 10,
                iat: 5,
                account: 'mockaccount',
                azp: 'test-client',
                email: 'test@example.com',
                family_name: 'Client',
                given_name: 'Test',
                name: 'Test Client',
                sub: 'prod-rio-users:mock-user',
                locale: 'en',
            },
            id_token: 'id_token',
            session_state: 'session_state',
            refresh_token: 'refresh_token',
            token_type: 'token_type',
            scope: 'scope',
            expires_at: 100000,
            state: 'state',
        };

        const user = new User(userSettings);
        oauthConfig.onSessionRenewed(adaptPublishedInfo(userSettings));
        return Promise.resolve(user);
    };

    const clearStaleState = () => {
        console.info('[configuration/login/oidc-session] Stale state cleared');
        return Promise.resolve();
    };

    return {signinSilent, clearStaleState};
};
