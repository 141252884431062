import { ENTITY_FILE_DELIVERABLE } from '~/features/base/constants/entities';

import { aggregationStoreSelector } from '~/features/higherorder/selectors/dataSelectors';
import get from 'lodash/fp/get';

// Selectors
const fileDeliverableAggregationsSelector = state => aggregationStoreSelector(state)[ENTITY_FILE_DELIVERABLE];

 // Exports

export const fileDeliverableCountSelector = state => get('totalCount', fileDeliverableAggregationsSelector(state));
