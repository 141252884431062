import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import merge from 'lodash/fp/merge';

import {detectFeatureToggles} from './setup/featureToggles';

import {cnDevConfig} from "~/env/env.cndev";
import {localAuthConfig} from "~/env/env.localauth";
import {localConfig} from "~/env/env.local";
import {localAzure} from "~/env/env.localazure";
import {tratonDevConfig} from "~/env/env.traton.dev";

export const isProdEnvironment = host => {
    return false; // No production exists
};

export const isTratonDevEnvironment = host => {
    return includes('traton-ota-loop-test', host);
};

export const isHeroCnDevEnvironment = host => {
    return includes('hero.devtest.aws.scania.com.cn', host);
};

export const getRuntimeConfig = (window, env = {}) => {
    const defaultConfig = {
        backend: {},
        homeRoute: `${window.location.origin}`,
        id: 'env.stub',
        login: {
            oauthScope: [
                'openid',
                'profile',
                'email'
            ],
            mockLocale: 'en-GB',
            preventRedirect: false,
        },
        sentryToken: 'https://6e5484a7f1454a408493d98937cf4051@sentry.io/295340',
    };

    let config = localConfig;

    if (env.isTratonDevEnv || env.featureToggles.enforcedEnv === 'tratondev') {
        config = tratonDevConfig;
    }

    if (env.isHeroDevEnv || env.featureToggles.enforcedEnv === 'herodev') {
        config = cnDevConfig;
    }

    if (env.featureToggles.enforcedEnv === 'localazure') {
        config = localAzure;
    }

    if (env.featureToggles.enforcedEnv === 'localauth') {
        config = localAuthConfig;
    }

    return merge(merge({}, defaultConfig), config);
};

export const configureEnv = (window, process) => {
    const {location: {host}} = window;
    const {env: {NODE_ENV}} = process;
    const isProdBuild = import.meta.env.PROD;
    const isProdEnv = isProdEnvironment(host);
    const isHeroDevEnv = isHeroCnDevEnvironment(host)
    const isTratonDevEnv = isTratonDevEnvironment(host)
    const isLocalEnv = !isProdEnv && !isHeroDevEnv && !isTratonDevEnv;

    const shouldSendMetrics = isProdEnv;
    const runsInSandbox = isProdBuild && !isProdEnv;
    const shouldRestrictLog = import.meta.env.PROD;
    const shouldUseConsoleLogger = false; //NODE_ENV !== 'test' && !shouldRestrictLog;

    const featureToggles = detectFeatureToggles(window);

    const env = {
        featureToggles,
        isLocalEnv,
        isProdBuild,
        isHeroDevEnv,
        isTratonDevEnv,
        runsInSandbox,
        runtimeConfig: {},
        shouldRestrictLog,
        shouldUseConsoleLogger,
        shouldSendMetrics,
    };

    env.runtimeConfig = getRuntimeConfig(window, env);

    return env;
};

export const env = configureEnv(window, process);

if (env.featureToggles.tracing) {
    // eslint-disable-next-line no-console
    console.log(env);
}
