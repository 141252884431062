import omit from 'lodash/fp/omit';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import HwVariantPrefixFormItem from '~/features/base/components/forms/HwVariantPrefixFormItem';
import ActivatedFormItem from '~/features/base/components/forms/ActivatedFormItem';
import VehicleVariantsFormItem from '~/features/base/components/forms/VehicleVariantsFormItem';
import { allVehicleVariants } from '~/features/vehicleVariants/constants/vehicleVariantsConstants';
import HardwareVariantsFormItem from '~/features/base/components/forms/HardwareVariantsFormItem';
import { connect } from 'react-redux';
import { hwVariantsSelector } from '~/features/hwVariants/selectors/hwVariantsSelectors';
import { fetchHwVariants } from '~/features/hwVariants/actions/hwVariantsActions';
import VINListFormItem from '~/features/base/components/forms/VINListFormItem';
import InstalledDeliverablesFormGroup from '~/features/groups/components/InstalledDeliverablesFormGroup';
import { groupsDependenciesSelector } from '~/features/deliverables/selectors/deliverableSelectors';

export class GroupCriteriaEditorForm extends Component {
    onInstalledDeliverablesChange = (installedDeliverables) => {
        const { autoGroupCriteria } = this.props;
        this.props.onChange({
            ...autoGroupCriteria,
            installedDeliverables,
        });
    };

    onHwVariantPrefixChange = ({ hwVariantPrefix }) => {
        const { autoGroupCriteria } = this.props;
        if (!size(hwVariantPrefix)) {
            this.props.onChange(omit(['hwVariantPrefix'], autoGroupCriteria));
        } else {
            this.props.onChange({
                ...autoGroupCriteria,
                hwVariantPrefix,
            });
        }
    };
    onHwVariantListChange = ({ hwVariantList }) => {
        const { autoGroupCriteria } = this.props;
        if (!size(hwVariantList)) {
            this.props.onChange(omit(['hwVariantList'], autoGroupCriteria));
        } else {
            this.props.onChange({
                ...autoGroupCriteria,
                hwVariantList,
            });
        }
    };
    onVehicleVariantFilterChange = (vehicleVariants) => {
        const { autoGroupCriteria } = this.props;
        if (!size(vehicleVariants.vehicleVariantsWhitelist)) {
            const a = omit(['vehicleVariants'], autoGroupCriteria);
            this.props.onChange(a);
        } else {
            this.props.onChange({
                ...autoGroupCriteria,
                vehicleVariants: vehicleVariants.vehicleVariantsWhitelist,
            });
        }
    };
    onInCustomerFleetChange = ({ inCustomerFleet }) => {
        const { autoGroupCriteria } = this.props;
        if (inCustomerFleet === undefined) {
            this.props.onChange(omit(['inCustomerFleet'], autoGroupCriteria));
        } else {
            this.props.onChange({
                ...autoGroupCriteria,
                inCustomerFleet,
            });
        }
    };

    onVinListChange = ({vinList}) => {
        const {autoGroupCriteria} = this.props;
        if (!size(vinList)) {
            this.props.onChange(omit(['vinList'], autoGroupCriteria));
        } else {
            this.props.onChange({
                ...autoGroupCriteria,
                vinList
            });
        }
    };

    render() {
        const { autoGroupCriteria, hwVariants, deliverables } = this.props;

        return [
            <InstalledDeliverablesFormGroup values={autoGroupCriteria.installedDeliverables} deliverables={deliverables}
                                            onChange={this.onInstalledDeliverablesChange}/>,
            <HwVariantPrefixFormItem value={autoGroupCriteria.hwVariantPrefix}
                                     onChange={this.onHwVariantPrefixChange}/>,
            <HardwareVariantsFormItem
                whiteListedHardwareVariants={autoGroupCriteria.hwVariantList}
                hardwareVariants={hwVariants}
                onChange={this.onHwVariantListChange}/>,

            <VehicleVariantsFormItem
                whiteListedVehicleVariants={autoGroupCriteria.vehicleVariants || []}
                vehicleVariants={allVehicleVariants}
                onChange={this.onVehicleVariantFilterChange}/>,

            <VINListFormItem label='intl-msg:vinListAssigned'
                             vinList={autoGroupCriteria.vinList}
                             onChange={this.onVinListChange}/>,

            <ActivatedFormItem value={autoGroupCriteria.inCustomerFleet}
                               onChange={this.onInCustomerFleetChange}/>,
        ];
    }

    componentWillMount() {
        this.props.fetchHwVariants();
    }
}

const mapStateToProps = (state) => ({
    hwVariants: hwVariantsSelector(state),
    deliverables: groupsDependenciesSelector(state),
});

const mapDispatchToProps = dispatch => ({
    fetchHwVariants: () => {
        dispatch(fetchHwVariants());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupCriteriaEditorForm);

GroupCriteriaEditorForm.defaultProps = {
    // props
    apps:[],
    // functions
    onChange: noop,
};

GroupCriteriaEditorForm.propTypes = {
    // props
    autoGroupCriteria: PropTypes.object,
    apps: PropTypes.array,
    // functions
    onChange: PropTypes.func,
};
