// This is the runtime configuration being used for production
// where authorization is done via the `authorization-server`.

const clientId = '4dd848b1-a6d8-479b-8b18-08868860f22a';
const authority = 'https://login.partner.microsoftonline.cn/e71a154e-e269-46af-a3af-fdff3eab0663';
const oauthService = 'https://login.partner.microsoftonline.cn/e71a154e-e269-46af-a3af-fdff3eab0663/v2.0';
const appUri = 'https://mdm-admin-dev.hero.devtest.aws.scania.com.cn';
const redirectUri = `${appUri}/redirect.html`;

export const cnDevConfig = {
    id: 'env.cndev',
    region: 'CN',
    backend: {
        AUTHENTICATION_SERVICE: oauthService,
        DEVICE_MANAGEMENT_SERVICE: 'https://mdm-dev.hero.devtest.aws.scania.com.cn/api/mdmservice',
        // Initially not required for hero dev environment.
        //DEVICE_SYSTEM_STATE_SERVICE: 'https://system-state.traton-ota-loop-test.rio.cloud/api/tbm3systemstatenotification',
        DELIVERABLE_MANAGEMENT_SERVICE: 'https://mdm-dev.hero.devtest.aws.scania.com.cn/api/mdmservice',
        LOG_SERVICE: 'https://mdm-logging-dev.hero.devtest.aws.scania.com.cn/api/drive/remotelogging',
    },
    homeRoute: 'https://mdm-admin-dev.hero.devtest.aws.scania.com.cn/',
    appMenuUri: 'https://mdm-admin-dev.hero.devtest.aws.scania.com.cn/#app-menu',
    userMenuUri: 'https://mdm-admin-dev.hero.devtest.aws.scania.com.cn/#user-menu',
    login: {
        authority: authority,
        clientId: clientId,
        resource: clientId,
        mockAuthorization: false,
        preventRedirect: false,
        redirectUri: redirectUri,
        silentRedirectUri: redirectUri,
    },
    logoutUri: `${oauthService}/logout?redirect_uri=${appUri}`,
    networkCodes: {
        0: {name: 'Unknown', iso2: 'xx'},
        240: { name: 'Sweden', iso2: 'se' },
        460: {name: 'China', iso2: 'cn'},
        262: { name: 'Germany', iso2: 'de' },
    },
    networkCodesWhiteList: [240, 262, 460],
};