import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';

import { getDeliverableManagementBackend } from '~/features/base/selectors/backendSelectors';

import { deleteHTTP, doHandleErrorSaga, getHTTP } from '~/features/base/sagas/sagaUtil';

import { ENTITY_DELIVERABLE } from '~/features/base/constants/entities';
import { failedAction, finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';
import { uploadRequest } from '~/features/base/actions/data/fileUploadActions';
import { UPLOAD_DELIVERABLE_FILE } from '~/features/deliverables/actions/deliverableFileActions';
import { hideModal } from '~/features/base/actions/ui/modalsActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import download from 'downloadjs';

export function* getDeliverablesURL() {
    const serviceURL = yield select(getDeliverableManagementBackend);
    return `${serviceURL}/deliverables/v1`;
}

export function* doUploadDeliverableFileSaga(action) {
    yield put(startLoading(ENTITY_DELIVERABLE));
    try {
        const url = yield call(getDeliverablesURL);
        const file = action.payload.file[0];
        const { context, deliverableId, deliverableVersion, deliverableType } = action.payload;
        // const deliverableName = encodeURIComponent(`${deliverableType.toLowerCase()}::${context}::${deliverableId}`);
        const deliverableName = encodeURIComponent(`distro::${context}::${deliverableId - deliverableVersion}`);
        const fileName = encodeURIComponent(file.name);
        yield put(uploadRequest(file, `${url}/${deliverableName}/files/${fileName}/upload?compressed=false`));
        yield put(hideModal());
        // yield put(triggerDataFetcher());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_DELIVERABLE));
    yield put(finishedAction(action.type));
}


export function* doDeleteDeliverableFileSaga(action) {
    try {
        const url = yield call(getDeliverablesURL);
        const { deliverableId, fileName } = action.payload;
        yield call(deleteHTTP, `${url}/${deliverableId}/files/${fileName}`);
        yield put(hideModal());
        yield put(triggerDataFetcher());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doDownloadDeliverableFileSaga(action) {
    try {
        const url = yield call(getDeliverablesURL);
        const { deliverableId, fileName } = action.payload;
        const response = yield call(getHTTP, `${url}/${deliverableId}/files/${fileName}/download`);
        fetch(response.url,
            {
                method: 'GET',
            },
        ).then((data) => {
            data.blob().then(blob => download(blob, fileName, 'application/octet-stream'));
        });
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
    }
}

export function* uploadDeliverableFileSagaNeo() {
    yield takeLatest(UPLOAD_DELIVERABLE_FILE, doUploadDeliverableFileSaga);
    yield put(triggerDataFetcher());
}

