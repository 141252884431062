import React from 'react';

import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { FormattedMessage } from 'react-intl';
import FieldOrNoData from '../../base/components/FieldOrNoData';
import { ColumnDescriptorModel } from '../../base/models/ColumnDescriptorModel';
import DeliverableIdChip from '~/features/deliverables/components/DeliverableIdChip';
import DeliverableChip from '~/features/deliverables/components/DeliverableChip';


export const LiveRolloutsColumnDescriptors: ColumnDescriptorModel[] = [{
    id: 'version',
    field: 'deliverableVersion',
    label: 'intl-msg:version',
    format: (field, item) => {
        return field.description ?
            <OverlayTrigger placement='top' overlay={
                <Tooltip id='tooltip-description' className='top-right'>
                    <FormattedMessage id={field.description}/>
                </Tooltip>}>
                <div className={'ellipsis-2'}>
                    <div>
                        <DeliverableChip deliverableId={field.deliverableId}
                                         deliverableVersion={field.version}
                                         deliverableType={item.deliverableType}/>
                    </div>
                </div>
            </OverlayTrigger> : <FieldOrNoData field={field.version}/>;
    },
},
    {
        id: 'id',
        field: 'deliverableId',
        label: 'intl-msg:id',
        format: (field, item) => {
            return (<DeliverableIdChip deliverableId={field} deliverableType={item.deliverableType.toLowerCase()}/>);
        },
    },
    {
        id: 'progress',
        field: 'progress',
        label: 'intl-msg:progress',
        format: field => {
            return <div className='progress height-20 margin-bottom-0'>
                <OverlayTrigger placement='top' overlay={
                    <Tooltip id='tooltip-progress'
                             className='top-right'>
                        <FormattedMessage
                            id={`${field.successfulRollouts} / ${field.estimatedTargets}`}/>
                    </Tooltip>}>
                    <div
                        className='progress-bar progress-bar-success progress-striped text-size-14 text-bold'
                        style={{
                            width: `${field?.successfulRolloutsPercentage}%`,
                            color: field?.successfulRolloutsPercentage < 20
                                ? 'black' : 'white',
                        }}
                    >{`${field?.successfulRolloutsPercentage}%`}</div>
                </OverlayTrigger>
            </div>;
        },
    }];
