import {
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
} from '~/features/deliverables/constants/deliverablesParameters';
import { CM4 } from '~/features/devices/constants/deviceTypes';

const defaultDevice = {
    [DELIVERABLE_TYPE_CM4G]: CM4,
    [DELIVERABLE_TYPE_BUNDLE]: CM4,
}

export const getDefaultDeviceType = (deliverableType) => {
    return deliverableType ? defaultDevice[deliverableType] : '';
}
