import size from 'lodash/fp/size';

import React from 'react';

import { AUTOMATIC } from '~/features/groups/constants/groupTypes';

import DeviceChipList from '~/features/devices/components/DeviceChipList';
import GroupChip from '~/features/groups/components/GroupChip';
import GroupType from '~/features/groups/components/GroupType';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import { FormattedNumber } from 'react-intl';

export const groupRowDescriptors = [{
    field: 'name',
    label: 'intl-msg:name',
    format: (field, group) => <GroupChip group={group}/>,
}, {
    field: 'type',
    label: 'intl-msg:type',
    format: field => <GroupType groupType={field}/>,
}, {
    field: 'description',
    label: 'intl-msg:description',
    format: field => <FieldOrNoData field={field}/>,
}, {
    field: 'creator',
    label: 'intl-msg:creator',
    format: field => <FieldOrNoData field={field}/>,
}, {
    field: 'autoGroupCriteria',
    label: 'intl-msg:filter',
    fieldCondition: group => group.type === AUTOMATIC,
    format: field => {
        return <pre>{JSON.stringify(field, null, 4)}</pre>;
    },
}, {
    field: 'groupSize',
    label: 'intl-msg:count',
    format: field => <FormattedNumber value={field}/>,
}, {
    field: 'serialNumbers',
    label: 'intl-msg:devicesAssigned',
    format: field => {
        if (field && size(field)) {
            return <DeviceChipList serialNumbers={field}/>;
        }
    },
}];

export const groupReferencesRowDescriptors = [ {
    field: 'deliverables',
    label: 'intl-msg:files',
    format: field => {
        return <DeliverableChipList type={'FILE'} distroVersions={field}/>;
    },
}];
