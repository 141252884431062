// import '~/setup/polyfills';

import React from 'react';
import { render } from 'react-dom';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { history, store } from '~/setup/store';
import { attemptInitialSignIn } from '~/setup/oauth';

import { getLocale } from '~/features/lang/selectors';
import { extractLanguage } from '~/features/lang/lang';
import { configureFetchLanguageData } from '~/features/lang/services';
import { configureMockUserManager, configureUserManager, createUserManager } from '~/features/login/login';
import { userProfileObtained, userSessionExpired, userSessionRenewed } from '~/features/login/actions';
import { accessToken } from '~/features/tokenHandling/accessToken';
import { accessTokenStored, idTokenStored } from '~/features/tokenHandling/actions';

import { env, isProdEnvironment } from '~/env';

const { useValidToken } = env.featureToggles;

import IntlContainer from '~/features/base/components/IntlContainer';
import MainContainer from '~/features/base/components/MainContainer';

import { NoMatch } from '~/features/base/components/NoMatch';

import '~/style/css/main.less'; // TODO Maybe move away to actual app
import TabVisibilitySensor from './features/base/components/TabVisibilitySensor';
import { GA4React } from 'ga-4-react';
import {trace} from "~/setup/trace";

let googleAnalyticsInstance;

const isGoogleAnalyticsActive = () => {
    const { location: { host } } = window;
    return googleAnalyticsInstance && isProdEnvironment(host);
};

export const sendGoogleAnalyticsEvent = (pageTitle, method) => {
    if (isGoogleAnalyticsActive()) {
        googleAnalyticsInstance.gtag('event', pageTitle, { 'method': method });
    }
};

export const sendGoogleAnalyticsPageView = (path) => {
    if (isGoogleAnalyticsActive()) {
        googleAnalyticsInstance.pageview(path);
    }
};

const main = async () => {
    const root = document.querySelector('#root');

    // Initialise Google Analytics
    const ga4react = new GA4React('G-6323F9NV7L');

    let renderApp = () => {
        renderApp = () => {
            render(
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <TabVisibilitySensor/>
                        <IntlContainer>
                            <Switch>
                                <Route path='/error' component={NoMatch}/>
                                <Route path='/' component={MainContainer}/>
                                <Route component={NoMatch}/>
                            </Switch>


                        </IntlContainer>
                    </ConnectedRouter>
                </Provider>,
                root,
            );
        };

        if (isGoogleAnalyticsActive()) {
            (async _ => {
                await ga4react.initialize()
                    .then((ga4) => {
                        googleAnalyticsInstance = ga4;
                    })
                    .catch(err =>
                        console.error('Analytics Failure.'),
                    )
                    .finally(() => {
                            renderApp();
                        },
                    );
            })();
        } else {
            renderApp();
        }
    };
    const fetchLanguageData = configureFetchLanguageData(store);

    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });

    const oauthConfig = {
        onSessionExpired: () => {
            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: result => {
            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(userProfileObtained(result.profile));
            store.dispatch(userSessionRenewed());

            // You will need to get the user language by yourself then
            // you may fetch the suitable messages from the CDN. Depending
            // on when and from where you fetch the user settings you might
            // want to employ a loading spinner while the request is ongoing.
            fetchLanguageData(result.locale);
        },
    };
    const isAllowedToMockAuth = env.isLocalEnv || (!import.meta.env.PROD || useValidToken);
    const userManager =
        isAllowedToMockAuth && env.runtimeConfig.login.mockAuthorization
            ? configureMockUserManager(oauthConfig)
            : configureUserManager(oauthConfig, createUserManager());

    try {
        await userManager.clearStaleState();
        await attemptInitialSignIn(userManager);
        renderApp();
    } catch (error) {
        trace('Could not start application', error);
    }
};

main();
