import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';
import find from 'lodash/fp/find';
import isEmpty from 'lodash/fp/isEmpty';
import _map from 'lodash/map';

import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

import uid from '~/features/base/utils/uid';
import classNames from 'classnames';
import {
    showDeliverableDeletionDialog,
    showDeliverableEditorDialog,
    showDeliverableVersionDeletionDialog,
} from '~/features/deliverables/actions/deliverableActions';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { uploadDeliverableFile } from '~/features/deliverables/actions/deliverableFileActions';
import ReleaseState from '~/features/base/components/ReleaseState';
import {
    canDeleteDeliverablesSelector,
    canUpdateDeliverablesSelector,
    deliverablePageItemsSelector,
    deliverablesContextsSelector,
    deliverablesLoadingSelector,
} from '~/features/deliverables/selectors/deliverableSelectors';
import { artifactPageItemsSelector } from '~/features/deliverables/features/artifacts/selectors/artifactSelectors';
import DeliverableColumnMenu from '~/features/deliverables/components/DeliverableColumnMenu';
import isEqual from 'lodash/fp/isEqual';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import { FETCH_DELIVERABLES_AMOUNT_INCREMENT } from '~/features/deliverables/constants/deliverablesParameters';
import { parseQuery } from '~/features/base/utils/query';
import { fetchFilteredArtifacts } from '~/features/deliverables/features/artifacts/actions/artifactActions';

export class DeliverableVersionsList extends Component {
    constructor(props) {
        super(props);
        this.name = uid();
    }

    handleSearchValueChange = (searchValue) => {
        this.updateFilter({ 'deliverableVersion': searchValue });
    };

    onCreateDeliverableVersion = (event) => {
        const deliverableId = event.currentTarget.getAttribute('data-key-deliverable-id');
        this.props.onShowDeliverableEditorDialog({
            isNew: true,
            isDeliverableVersion: true,
            deliverableId,
        });
    };

    updateFilter(filter) {
        let { pathname, search, match } = this.props;
        const { deliverableVersion } = match.params;
        if (!isEmpty(deliverableVersion)) {
            pathname = pathname.replace(`/${deliverableVersion}`, '');
        }

        const query = parseQuery(search);
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                ...filter,
                page: 0,
                size: FETCH_DELIVERABLES_AMOUNT_INCREMENT,
            },
        });
    }

    onEditDeliverableVersion = () => {
        const { deliverables, selectedDeliverableVersion: deliverableVersion } = this.props;
        const selectedDeliverableDetails = find(function(o) {
            return toShortSemanticVersion(o.deliverableVersion) === deliverableVersion;
        }, deliverables);
        const {
            deliverableId,
            description,
            downloadUrl,
            fileInfo,
            networkCodeWhitelist,
            owningContext,
            priority,
            releaseState,
        } = selectedDeliverableDetails;
        this.props.onShowDeliverableEditorDialog({
            isNew: false,
            isDeliverableVersion: true,
            deliverableId,
            description,
            downloadUrl,
            deliverableVersion,
            fileInfo,
            networkCodeWhitelist,
            owningContext,
            priority,
            releaseState,
        });
    };

    onDeleteDeliverableVersion = () => {
        const { deliverables, selectedDeliverableVersion: deliverableVersion } = this.props;
        const selectedDeliverableDetails = find(function(o) {
            return toShortSemanticVersion(o.deliverableVersion) === deliverableVersion;
        }, deliverables);
        const {
            deliverableId,
            deliverableType,
        } = selectedDeliverableDetails;
        this.props.onDeleteDeliverableVersion({
            deliverableId,
            deliverableType,
            deliverableVersion,
        });
    };

    render() {
        const {
            deliverables,
            deliverablesLoading,
            deliverableType,
            selectedDeliverableId,
            selectedDeliverableVersion,
            tableClassNames,
            search,
        } = this.props;

        const parsedQuery = parseQuery(search);
        const versionSearch = parsedQuery['deliverableVersion'];
        const tip = this.props.getDeliverableTooltipText();

        let searchResult;
        if (deliverables) {
            searchResult = !versionSearch ? deliverables : deliverables.filter(row => {
                const version = toShortSemanticVersion(row.deliverableVersion);
                return version.startsWith((versionSearch.toLowerCase()));
            });
        }

        const columnWidth = 'col-md-2';
        const deliverablesLoadedType = searchResult && searchResult[0]?.deliverableType;
        const isSameDeliverableLoadedType = deliverablesLoadedType?.toLowerCase() === deliverableType?.toLowerCase();
        return (
            <div className={columnWidth}>
                <div className={classNames('bg-white clearfix')}>
                    <div className='panel panel-primary margin-bottom-25'>
                        <div className='panel-heading height-50'>
                            <div className='col-md-8 text-size-16 padding-top-5'>
                                <FormattedMessage id='intl-msg:versions'/>
                                {` (${size(deliverables)})`}
                            </div>
                            <div className='col-md-4 text-right'>
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip id='tooltip' className='top-right'>
                                            {tip}
                                        </Tooltip>
                                    }
                                >
                                    <button type='button' className='btn btn-default width-50'
                                            data-key-deliverable-id={selectedDeliverableId}
                                            onClick={this.onCreateDeliverableVersion}>
                                        <span className={'rioglyph rioglyph-plus margin-0'}></span>
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className='table-toolbar-group-right padding-10'>
                            <div className='table-toolbar-column margin-top-5'>
                                <TableSearch
                                    value={versionSearch}
                                    onChange={this.handleSearchValueChange}
                                    placeholder={'Enter version id prefix ...'}
                                />
                            </div>
                        </div>
                        <div>
                            {size(searchResult) > 0 && isSameDeliverableLoadedType ?
                                <div>
                                    <div className=' overflow-y-scroll max-height-75vh padding-bottom-25-lg'>
                                        <table className={tableClassNames}>
                                            <tbody>
                                            {this.renderDeliverableVersionRows(searchResult, deliverableType,
                                                selectedDeliverableId, selectedDeliverableVersion)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                : !deliverablesLoading && !isSameDeliverableLoadedType ?
                                    <NotFoundState headline='Nothing found' message='Please refine your search'/>
                                    : <div className='padding-20 text-center'>
                                        <div className='display-inline-block'>
                                            <Spinner/>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDeliverableVersionRows(deliverables, deliverableType, selectedDeliverableId, selectedDeliverableVersion) {

        return _map(deliverables, (deliverable, index) => {
            const versionString = toShortSemanticVersion(deliverable.deliverableVersion);
            const items = [];
            items.push({
                value: < FormattedMessage id='intl-msg:edit'/>,
                onSelect: this.onEditDeliverableVersion,
                disabled: !(this.props.canUpdateDeliverables && this.props.canDeleteDeliverables),
            });
            items.push({
                value: <FormattedMessage id='intl-msg:delete'/>,
                onSelect: this.onDeleteDeliverableVersion,
                disabled: !(this.props.canUpdateDeliverables && this.props.canDeleteDeliverables),
            });

            const isCurrentVersionSelected = isEmpty(selectedDeliverableVersion) ? index === 0
                : selectedDeliverableVersion === versionString;
            return (
                <tr key={uid()} className={isCurrentVersionSelected ? 'active' : ''}
                    onClick={() => {
                        this.props.onVersionSelect(deliverableType, selectedDeliverableId,
                            versionString, deliverable.owningContext);
                    }}>
                    <td>
                        <div>
                            <div className='width-70 pull-left'>
                                <b>{versionString}</b>
                            </div>
                            <div className='width-70 pull-left text-center'>
                                {<ReleaseState className=''
                                               releaseState={deliverable.releaseState}/>}
                            </div>
                        </div>
                    </td>
                    {size(items) > 0 &&
                        <td className='width-20'>
                            <DeliverableColumnMenu items={items}></DeliverableColumnMenu>
                        </td>
                    }
                </tr>
            );
        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!isEqual(nextProps.deliverables, this.props.deliverables)) {
            return true;
        }
        if (nextProps.selectedDeliverableId !== this.props.selectedDeliverableId) {
            return true;
        }
        if (!nextProps.deliverablesLoading && this.props.deliverablesLoading && isEmpty(this.props.deliverables)) {
            return true;
        }
        if (nextProps.selectedDeliverableVersion !== this.props.selectedDeliverableVersion) {
            return true;
        }
        if (nextProps.search !== this.props.search) {
            return true;
        }
        if (nextProps.deliverablesLoading !== this.props.deliverablesLoading) {
            return true;
        }
        return false;
    }
}

export const mapStateToProps = (state) => {
    return {
        pathname: pathnameSelector(state),
        deliverablesLoading: deliverablesLoadingSelector(state),
        search: searchSelector(state),
        deliverables: deliverablePageItemsSelector(state),
        artifacts: artifactPageItemsSelector(state),
        deliverablesContexts: deliverablesContextsSelector(state),
        canUpdateDeliverables: canUpdateDeliverablesSelector(state),
        canDeleteDeliverables: canDeleteDeliverablesSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        onShowDeliverableEditorDialog: (options) => {
            dispatch(showDeliverableEditorDialog(options));
        },
        uploadDeliverableFile: (owningContext, deliverableId, deliverableVersion, deliverableType, file) => {
            dispatch(uploadDeliverableFile(owningContext, deliverableId, deliverableVersion, deliverableType, file));
        },
        showDeliverableDeletionDialog: (options) => {
            dispatch(showDeliverableDeletionDialog(options));
        },
        onDeleteDeliverableVersion: (options) => {
            dispatch(showDeliverableVersionDeletionDialog(options));
        },
        fetchFilteredArtifacts: (options) => {
            dispatch(fetchFilteredArtifacts(options));
        },
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableVersionsList);

DeliverableVersionsList.defaultProps = {
    // props
    expanderOpen: false,
    deliverableType: '',
    selectedDeliverableId: '',
    selectedDeliverableVersion: '',
    selectedContext: '',
    deliverables: [],
    deliverablesLoading: false,
    canUpdateDeliverables: true,
    canDeleteDeliverables: true,
    // functions
    followRoute: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    onShowPage: noop,
    onLoadMore: noop,
    onShowDeliverableEditorDialog: noop,
    onCreateDeliverableVersion: noop,
    onEditDeliverable: noop,
    showDeliverableDeletionDialog: noop,
    onDeleteDeliverableVersion: noop,
};

DeliverableVersionsList.propTypes = {
    // props
    expanderOpen: PropTypes.bool,
    deliverableType: PropTypes.string,
    selectedDeliverableId: PropTypes.string,
    selectedDeliverableVersion: PropTypes.string,
    selectedContext: PropTypes.string,
    deliverables: PropTypes.array,
    deliverablesLoading: PropTypes.bool,
    canUpdateDeliverables: PropTypes.bool,
    canDeleteDeliverables: PropTypes.bool,
    // functions
    followRoute: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    onShowPage: PropTypes.func,
    onLoadMore: PropTypes.func,
    onCreateDeliverable: PropTypes.func,
    onShowDeliverableEditorDialog: PropTypes.func,
    onCreateDeliverableVersion: PropTypes.func,
    onEditDeliverable: PropTypes.func,
    showDeliverableDeletionDialog: PropTypes.func,
    onDeleteDeliverableVersion: PropTypes.func,
};
