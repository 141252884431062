import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

import uid from '~/features/base/utils/uid';
import defaults from 'lodash/fp/defaults';
import { queryToSearchCriteria } from '~/features/systemState/utils/systemStatesQuery';
import { systemStateNotificationTypes } from '~/features/systemState/constants/systemStateNotificationTypes';
import map from 'lodash/fp/map';
import includes from 'lodash/fp/includes';
import { connect } from 'react-redux';
import {
    fetchSystemStateNotificationsOfDevice,
    requestNewSystemStateNotifications,
} from '~/features/systemState/actions/systemStateActions';
import {
    deviceSystemStateNotificationsForSerialNumberSelector,
    deviceSystemStateNotificationsLoadingSelector,
    deviceSystemStateNotificationsTotalElementsSelector,
} from '~/features/systemState/selectors/systemStateSelectors';
import { ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS_SCOPE_DEVICE } from '~/features/base/constants/entities';
import size from 'lodash/fp/size';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import SystemStateNotificationListTableToolbar
    from '~/features/systemState/components/SystemStateNotificationListTableToolbar';
import SystemStateNotificationsTable from '~/features/systemState/components/SystemStateNotificationsTable';
import { P0, systemStatePartitionTypes, TELE } from '~/features/systemState/constants/systemStatePartitionTypes';
import hotkeys from 'react-keyboard-shortcuts/lib/hotkeys';
import { CM4 } from '~/features/devices/constants/deviceTypes';

export const devicePartitions = {
    [CM4]: [P0],

};

const FETCH_UPDATE_EVENTS_AMOUNT_INCREMENT = 50;
const defaultFilters = {
    from: undefined,
    to: undefined,
    types: [],
    size: FETCH_UPDATE_EVENTS_AMOUNT_INCREMENT,
};

export class DeviceSystemStatesDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
        this.state = {
            filters: defaultFilters,
            partition: props.deviceType === CM4 ? systemStatePartitionTypes[P0] : systemStatePartitionTypes[TELE],
        };
        this.updateFilter(defaultFilters);
    }

    selectionByIds = values => map(option =>
        ({
            ...option,
            label: this.props.intl.formatMessage({ id: option.label }),
            selected: includes(option.id, values),
        }),
    );

    clearFilters = () => {
        this.updateFilter({
            ...defaultFilters,
            result: undefined,
        });
    };

    onLoadMore = () => {
        this.updateFilter(
            {
                size: this.state.filters.size + FETCH_UPDATE_EVENTS_AMOUNT_INCREMENT,
            },
        );
    };

    onRequestNew = () => {
        this.props.requestNewSystemStateNotifications(this.props.serialNumber, this.state.partition.id);
    };

    updateFilter = (filters) => {
        const updatedFilters = {
            ...this.state.filters,
            ...filters,
        };
        this.setState({
            filters: updatedFilters,
        });

        let searchCriteria = defaults(queryToSearchCriteria(updatedFilters), {
            serialNumber: this.props.serialNumber,
        });
        if (!filters?.types || size(filters?.types) === 0) {
            const types = Object.keys(systemStateNotificationTypes);
            searchCriteria.types = types.join(',');
        }
        if (!filters?.partitions || size(filters?.partitions) === 0) {
            const partitions = devicePartitions[this.props.deviceType];
            searchCriteria.partitions = partitions.join(',');
        }

        const fetchFilteredUpdateEventsOptions = {
            page: 0,
            size: updatedFilters.size,
            searchCriteria,
            scope: ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS_SCOPE_DEVICE,
        };
        this.props.fetchSystemStateNotificationsOfDevice(fetchFilteredUpdateEventsOptions);
    };

    // eslint-disable-next-line camelcase
    hot_keys = {
        'esc': {
            priority: 1,
            handler: () => {
                this.props.toggleSystemStatesDialog();
            },
        },
    };

    renderTitle() {
        return <FormattedMessage id='intl-msg:apps.deviceState.title'/>;
    }

    renderSystemStateNotifications() {
        const {
            systemStateNotifications,
            systemStateNotificationsLoading,
            totalElements,
            serialNumber,
            deviceType,
        } = this.props;
        const showLoadMore = totalElements > systemStateNotifications.length;

        if (!size(systemStateNotifications)) {
            return ([
                    <DefaultWhiteColumn className='padding-top-20 clearfix'>
                        <SystemStateNotificationListTableToolbar updateFilter={this.updateFilter}
                                                                 filters={this.state.filters}
                                                                 serialNumber={serialNumber} deviceType={deviceType}>
                            <span className='padding-top-5'>
                                {
                                    !systemStateNotificationsLoading ? <FormattedMessage
                                        id='intl-msg:totalSystemStateNotifications'
                                        values={{
                                            totalCount: totalElements,
                                        }}/> : null
                                }
                            </span>
                        </SystemStateNotificationListTableToolbar>
                    </DefaultWhiteColumn>,
                    <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
                        {systemStateNotificationsLoading ?
                            <Spinner/>
                            :
                            <NotFoundState fullWidth
                                           headline={<FormattedMessage
                                               id='intl-msg:nothingFound'/>}
                                           message={<FormattedMessage
                                               id='intl-msg:noUpdateEventsReportedYet'/>}/>}
                    </DefaultWhiteColumn>,
                ]
            );
        }

        return [
            <DefaultWhiteColumn className='padding-top-20 clearfix'>
                <SystemStateNotificationListTableToolbar updateFilter={this.updateFilter}
                                                         filters={this.state.filters}
                                                         columnSelectorEnabled
                                                         serialNumber={serialNumber}
                                                         deviceType={deviceType}>
                    <span className='padding-top-5'>
                        {
                            systemStateNotificationsLoading ? <Spinner/> :
                                <span>
                                    <FormattedMessage id='intl-msg:totalSystemStateNotifications'
                                                      values={{
                                                          totalCount: totalElements,
                                                      }}/>
                                    <span className='padding-left-10'>
                                        <a id='reload-system-state-notification'
                                           className='btn btn-default margin-right-5'
                                           onClick={this.updateFilter}>
                                            <span className='rioglyph rioglyph-refresh' aria-hidden='true'></span>
                                        </a>
                                    </span>
                                </span>
                        }
                    </span>
                    <div className='display-inline-block padding-top-5'>
                    </div>
                </SystemStateNotificationListTableToolbar>
            </DefaultWhiteColumn>,
            <DefaultWhiteColumn className='padding-top-20 padding-bottom-5'>
                {
                    !systemStateNotificationsLoading ?
                        <SystemStateNotificationsTable
                            systemStateNotifications={systemStateNotifications}/>
                        : null
                }
            </DefaultWhiteColumn>,
            showLoadMore &&
            <DefaultWhiteColumn className='display-flex justify-content-center padding-bottom-20'>
                {
                    !systemStateNotificationsLoading &&
                    <a id='load-more-button'
                       className='btn btn-default'
                       onClick={this.onLoadMore}>
                        <FormattedMessage id='intl-msg:loadMore'/>
                    </a>
                }
            </DefaultWhiteColumn>,
        ];
    }

    renderRequestBar() {
        return (
            <div>
                <div class="h5 margin-left-25"><FormattedMessage id='intl-msg:controlDevice.systemstate.requestNotification'/></div>
                <div className='display-flex row margin-left-25'>
                    <div className='btn-toolbar pull-right margin-top-25'>
                        <a id='new-system-state-notification-request'
                           className='btn btn-primary'
                           onClick={this.onRequestNew}>
                            <FormattedMessage id='intl-msg:requestNew'/>
                        </a>
                    </div>
                </div>
            </div>

        );
    }

    renderBody() {
        const { deviceType } = this.props;
        return [
            deviceType !== CM4 && this.renderRequestBar(),
            this.renderSystemStateNotifications(),
        ];
    }

    renderFooter() {
        return (
            <div>
                <button className='btn btn-default' onClick={this.props.toggleSystemStatesDialog}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
            </div>
        );
    }

    render() {
        const { toggleSystemStatesDialog } = this.props;
        const title = this.renderTitle();
        const body = this.renderBody();
        const footer = this.renderFooter();
        return (
            <Dialog className='device-editor-dialog'
                    show={true}
                    onHide={toggleSystemStatesDialog}
                    title={title}
                    body={body}
                    footer={footer}
                    useOverflow
                    bsSize={Dialog.SIZE_FULL_SCREEN}
            />
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        systemStateNotifications: deviceSystemStateNotificationsForSerialNumberSelector(state, ownProps),
        systemStateNotificationsLoading: deviceSystemStateNotificationsLoadingSelector(state, ownProps),
        totalElements: deviceSystemStateNotificationsTotalElementsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchSystemStateNotificationsOfDevice: (fetchFilteredSystemStateNotificationOptions) => {
            dispatch(fetchSystemStateNotificationsOfDevice(fetchFilteredSystemStateNotificationOptions));
        },
        requestNewSystemStateNotifications: (serialNumber, partition) => {
            dispatch(requestNewSystemStateNotifications(serialNumber, partition));
        },
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(hotkeys(DeviceSystemStatesDialog)));

DeviceSystemStatesDialog.defaultProps = {
    // props
    serialNumber: '',
    deviceType:'',
    systemStateNotifications: [],
    systemStateNotificationsLoading: false,
    // functions
    toggleSystemStatesDialog: noop,
    fetchSystemStateNotificationsOfDevice: noop,
    requestNewSystemStateNotifications: noop,
};

DeviceSystemStatesDialog.propTypes = {
    // props
    serialNumber: PropTypes.string,
    deviceType: PropTypes.string,
    systemStateNotifications: PropTypes.array,
    systemStateNotificationsLoading: PropTypes.bool,
    // functions
    toggleSystemStatesDialog: PropTypes.func,
    fetchSystemStateNotificationsOfDevice: PropTypes.func,
    requestNewSystemStateNotifications: PropTypes.func,
};
