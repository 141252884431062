import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import DeliverableTypeTabs from '~/features/devices/components/dialogs/quickWhitelisting/DeliverableTypeTabs';
import {
    fetchFilteredDeliverableIds,
    fetchFilteredDeliverables,
} from '~/features/deliverables/actions/deliverableActions';
import { connect } from 'react-redux';
import { TEST } from '~/features/base/constants/releaseStates';
import DeliverableList from '~/features/devices/components/dialogs/quickWhitelisting/DeliverableList';
import { FormattedMessage } from 'react-intl';
import { DeliverableType } from '~/features/deliverables/constants/DeliverableType';
import { CM4 } from '~/features/devices/constants/deviceTypes';
import {
    DEVICE_QUICK_WHITELISTING_MODE_UNINSTALL,
} from '~/features/devices/components/dialogs/quickWhitelisting/DeviceQuickWhitelistingDialog';

/**
 * Control device quick whitelisting form
 */
export class DeviceQuickWhitelistingForm extends PureComponent {
    constructor(props) {
        super(props);
    }

    onDeliverableTypeChange = (selectedDeliverableType) => {
        this.props.changeControlDeviceQuickWhitelisting({
            selectedDeliverableType,
            selectedDeliverableVersion: '',
            selectedDeliverableId: '',
            isAlreadyWhitelisted: false,
        });
        this.props.fetchFilteredDeliverableIds(0, selectedDeliverableType, '', TEST);

    };

    onDeliverableIdChange = (selectedDeliverableId) => {
        this.props.changeControlDeviceQuickWhitelisting({
            selectedDeliverableId,
            selectedDeliverableVersion: '',
            isAlreadyWhitelisted: false,
        });
    };

    onDeliverableVersionChange = (selectedDeliverableVersion, isAlreadyWhitelisted) => {
        this.props.changeControlDeviceQuickWhitelisting({
            selectedDeliverableVersion,
            isAlreadyWhitelisted,
        });
    };

    render() {
        const { mode, model, serialNumber, deviceType } = this.props;

        return [
            <DeliverableTypeTabs
                deviceType={deviceType}
                mode={mode}
                selectedDeliveryType={model.selectedDeliverableType}
                onDeliveryTypeChange={this.onDeliverableTypeChange}/>,
            <div className={'padding-top-20'}>
                <h6 className={'text-color-darker'}><FormattedMessage id='intl-msg:whitelistControlDevice.description'/>
                </h6>
                {mode === DEVICE_QUICK_WHITELISTING_MODE_UNINSTALL &&
                    <div className={'display-flex flex-row '}>
                        <h6 className={'text-color-warning rioglyph rioglyph-warning-sign  padding-right-5'}></h6>
                        <h6 className={' label-warning'}><FormattedMessage
                            id='intl-msg:whitelistControlDevice.uninstallDeliverable'/>
                        </h6>
                    </div>}
            </div>,
            <DeliverableList deliverableType={model.selectedDeliverableType}
                             mode={mode}
                             selectedDeliverableId={model.selectedDeliverableId}
                             selectedDeliverableVersion={model.selectedDeliverableVersion}
                             serialNumber={serialNumber}
                             onDeliverableIdChange={this.onDeliverableIdChange}
                             onDeliverableVersionChange={this.onDeliverableVersionChange}
                             onLoadMore={this.onLoadMore}/>,
        ];
    }

    componentDidMount() {
        const { deviceType } = this.props;
        let defaultDeliverableTypeSelected = '';

        if (deviceType === CM4) {
            defaultDeliverableTypeSelected = DeliverableType.CM4G;
        } 
        this.props.changeControlDeviceQuickWhitelisting({
            selectedDeliverableType: defaultDeliverableTypeSelected,
            selectedDeliverableVersion: '',
            selectedDeliverableId: '',
            isAlreadyWhitelisted: false,
        });
        this.props.fetchFilteredDeliverableIds(0, defaultDeliverableTypeSelected, '', TEST);
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    fetchFilteredDeliverables: (context, deliverableType, deliverableId, deliverableVersion) => {
        dispatch(fetchFilteredDeliverables(
            {
                page: 0,
                size: 200,
                searchCriteria: {
                    // owningContext: context,
                    deliverableType,
                    deliverableId,
                    deliverableVersion,
                },
            },
        ));
    },
    fetchFilteredDeliverableIds: (page, type, context, releaseState) => {
        const releaseStateValue = (releaseState && releaseState !== 'all') ? [releaseState] : undefined;
        dispatch(fetchFilteredDeliverableIds(
            {
                page: (page && page > 0) ? (page - 1) : 0,
                size: 200,
                searchCriteria: {
                    deliverableType: type,
                    // owningContext: context,
                    releaseStates: releaseStateValue,
                },
            },
        ));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceQuickWhitelistingForm);

DeviceQuickWhitelistingForm.defaultProps = {
    // props
    serialNumber: '',
    mode: '',
    model: {},
    // functions
    changeControlDeviceQuickWhitelisting: noop,
    fetchFilteredDeliverables: noop,
    fetchFilteredDeliverableIds: noop,
};

DeviceQuickWhitelistingForm.propTypes = {
    // props
    mode: PropTypes.string,
    serialNumber: PropTypes.string,
    model: PropTypes.object,
    // functions
    changeControlDeviceQuickWhitelisting: PropTypes.func,
    fetchFilteredDeliverables: PropTypes.func,
    fetchFilteredDeliverableIds: PropTypes.func,
};
