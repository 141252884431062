import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';

import { getDeliverableManagementBackend } from '~/features/base/selectors/backendSelectors';

import { deleteHTTP, doHandleErrorSaga, getHTTP, postHTTP } from '~/features/base/sagas/sagaUtil';

import {
    ENTITY_ARTIFACT,
    ENTITY_DELIVERABLES_CONTEXT_INFO,
    ENTITY_DELIVERABLES_USER_INFO,
} from '~/features/base/constants/entities';
import { failedAction, finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { mergeEntities, mergeEntity, resetEntity } from '~/features/higherorder/actions/entityActions';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';
import { hideModal } from '~/features/base/actions/ui/modalsActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { profileSelector } from '~/features/login/selectors';
import {
    ADD_NEW_CONTEXT,
    CREATE_ARTIFACT,
    DELETE_ARTIFACT,
    FETCH_DELIVERABLES_USER_INFO,
    FETCH_FILTERED_ARTIFACTS,
    fetchDeliverablesUserInfo,
} from '~/features/deliverables/features/artifacts/actions/artifactActions';

export function* getDeliverablesURL() {
    const serviceURL = yield select(getDeliverableManagementBackend);
    return `${serviceURL}/deliverables/v1`;
}

export function* getDeliverablesContextURL() {
    const serviceURL = yield select(getDeliverableManagementBackend);
    return `${serviceURL}/context/v1`;
}

export function* getDeliverablesUserInfoURL() {
    const serviceURL = yield select(getDeliverableManagementBackend);
    return `${serviceURL}/user/v1`;
}

export function* doFetchFilteredArtifactsSaga(action) {
    yield put(startLoading(ENTITY_ARTIFACT));
    try {
        const url = yield call(getDeliverablesURL);
        const { otaIdPrefix, type } = action.payload.searchCriteria;
        //TODO: What happens if context is null
        const context = action.payload.searchCriteria.contextList[0] || 'BASYS-FOTA';
        const deliverableName = encodeURIComponent(`${type}::${context}::${otaIdPrefix}`);
        const response = yield call(getHTTP, `${url}/${deliverableName}/files`);
        yield put(resetEntity(response, { entityName: ENTITY_ARTIFACT }));
        yield put(mergeEntities(response, { entityName: ENTITY_ARTIFACT }));
    } catch (error) {
        yield put(resetEntity([], { entityName: ENTITY_ARTIFACT }));

        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_ARTIFACT));
    yield put(finishedAction(action.type));
}

export function* doFetchDeliverablesUserInfoSaga(action) {
    yield put(startLoading(ENTITY_DELIVERABLES_USER_INFO));
    try {
        const url = yield call(getDeliverablesUserInfoURL);
        const response = yield call(getHTTP, `${url}/current`);
        yield put(mergeEntity(response, { entityName: ENTITY_DELIVERABLES_USER_INFO }));
    } catch (error) {
        //yield fork(doHandleErrorSaga, action.type, error);
        //yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_DELIVERABLES_USER_INFO));
    yield put(finishedAction(action.type));
}

export function* doCreateArtifactSaga(action) {
    try {
        const url = yield call(getDeliverablesURL);
        const file = action.payload.file;
        delete action.payload.file;
        action.payload = {
            ...action.payload,
            type: action.payload.type.toUpperCase(),
        };
        const response = yield call(postHTTP, url, JSON.stringify(action.payload));
        if (file) {
            yield put(uploadDeliverableFile(response.deliverableId, file));
        } else {
            yield put(hideModal());
            yield put(triggerDataFetcher());
        }
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doDeleteArtifactSaga(action) {
    try {
        const url = yield call(getDeliverablesURL);
        const artifactId = encodeURIComponent(action.payload.artifactId);
        yield call(deleteHTTP, `${url}/${artifactId}`);
        yield put(hideModal());
        yield put(triggerDataFetcher());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doAddNewContextSaga(action) {
    yield put(startLoading(ENTITY_DELIVERABLES_CONTEXT_INFO));
    const userProfile = yield select(profileSelector);
    try {
        const url = yield call(getDeliverablesContextURL);
        const payload = {
            name: action.newContextName,
            owners: [
                userProfile.unique_name,
            ],
        };
        yield call(postHTTP, url, JSON.stringify(payload));
        yield put(fetchDeliverablesUserInfo());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_DELIVERABLES_CONTEXT_INFO));
    yield put(finishedAction(action.type));
}

export function* fetchFilteredArtifactsSaga() {
    yield takeLatest(FETCH_FILTERED_ARTIFACTS, doFetchFilteredArtifactsSaga);
}

export function* fetchDeliverablesUserInfoSaga() {
    yield takeLatest(FETCH_DELIVERABLES_USER_INFO, doFetchDeliverablesUserInfoSaga);
}

export function* createArtifactSaga() {
    yield takeEvery(CREATE_ARTIFACT, doCreateArtifactSaga);
}

// export function* updateFileDeliverableSaga() {
//     yield takeEvery(UPDATE_FILE_DELIVERABLE, doUpdateFileDeliverableSaga);
// }

export function* deleteArtifactSaga() {
    yield takeEvery(DELETE_ARTIFACT, doDeleteArtifactSaga);
}

export function* addNewContextSaga() {
    yield takeLatest(ADD_NEW_CONTEXT, doAddNewContextSaga);
}
