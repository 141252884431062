// data
import { fetchUserSaga, showMissingUserRoleWarningDialogSaga } from '~/features/user/sagas/userSaga';
import {
    createControlDeviceSaga,
    fetchBlockedDeviceCountSaga,
    fetchControlDeviceCountSaga,
    fetchControlDeviceCountsByHwVariantInCustomerFleetSaga,
    fetchControlDeviceCountsByHwVariantSaga,
    fetchControlDeviceSaga,
    fetchDeviceStatusNamesSaga,
    fetchDeviceStatusTypesSaga,
    fetchErrorDeviceCountSaga,
    fetchFilteredControlDevicesSaga,
    fetchInCustomerFleetCountSaga,
    fetchTestDeviceCountSaga,
    fetchWarningDeviceCountSaga,
    triggerCheckForUpdateOnControlDeviceSaga,
    updateControlDeviceSaga,
} from '~/features/devices/sagas/controlDeviceSaga';
import {
    fetchControlDeviceEligibleDeliverablesVersionsSaga,
    fetchControlDeviceInfoSaga,
    showControlDeviceDetailsInSidebar,
} from '~/features/devices/sagas/controlDeviceInfoSaga';
import {
    fetchControlDeviceOperationsSaga,
    fetchControlDeviceRolloutStatesSaga,
} from '~/features/devices/sagas/controlDeviceOperationsSaga';

import { fetchCm4gCountSaga, fetchCm4gsSaga } from '~/features/deliverables/features/packages/sagas/packageSaga';
import { fetchCm4gDeliverableCountSaga } from '~/features/deliverables/features/packages/sagas/packageDeliverableSaga';
import {
    addDeviceToGroupSaga,
    createGroupSaga,
    deleteGroupSaga,
    fetchDeviceGroupsSaga,
    fetchFilteredGroupsSaga,
    fetchGroupSaga,
    fetchReferencesOfGroupSaga,
    removeDeviceFromGroupSaga,
    updateDeviceGroupsSaga,
    updateGroupSaga,
} from '~/features/groups/sagas/groupSaga';
import { fetchSerialNumbersOfGroupSaga } from '~/features/groups/sagas/groupDevicesAssignedSaga';
import {
    exportFilteredUpdateEventsSaga,
    fetchFilteredUpdateEventsSaga,
    fetchUpdateEventCountSaga,
    fetchUpdateEventCountsByTimeIntervalSaga,
    fetchUpdateEventReportSaga,
    fetchUpdateEventSuccessAndFailedSaga,
} from '~/features/updateEvents/sagas/updateEventSaga';

import { fetchAppsSaga } from '~/features/apps/sagas/appsSaga';
// ui
import { followRouteSaga } from '~/features/base/sagas/ui/routeSaga';
import { triggerDataFetcherSaga } from '~/features/base/sagas/ui/fetchDataSaga';

import { exportDevicesSaga } from '~/features/devices/sagas/exportSaga';

import { fetchHomeDataSaga } from '~/features/overview/sagas/homeSaga';

import { showErrorMessagesSaga } from '~/features/base/sagas/ui/notificationsSaga';

import { showControlDeviceEditorSaga } from '~/features/devices/sagas/controlDeviceEditorSaga';
import {
    fetchSerialNumbersAndOpenEditorOfGroupSaga,
    showGroupDeletionDialogSaga,
    showGroupEditorDialogSaga,
} from '~/features/groups/sagas/groupEditorSaga';
import {
    fetchFileDeliverableCountSaga,
    showFileDeletionDialogSaga,
    showFileEditorDialogSaga,
} from '~/features/deliverables/features/files/sagas/fileEditorSaga';

import { showUpdateEventReportDialogSaga } from '~/features/updateEvents/sagas/updateEventReportSaga';
import { uploadRequestWatcherSaga } from '~/features/base/sagas/fileUploadSaga';
import { fetchHwVariantsSaga } from '~/features/hwVariants/sagas/hwVariantsSaga';
import {
    fetchStateNotificationsOfDeviceSaga,
    requestNewStateNotificationSaga,
    setSystemStateColumnSettingsSaga,
} from '~/features/systemState/sagas/systemStateSaga';
import { fetchVehicleSparePartNumbersSaga } from '~/features/vehicleSparePartNumbers/sagas/vehicleSparePartNumbersSaga';
import {
    createJiraIssueSaga,
    findJiraIssuesSaga,
    showJiraIssueEditorSaga,
} from '~/features/atlassian/sagas/atlassianJiraSaga';
import { fetchVehicleVariantsSaga } from '~/features/vehicleVariants/sagas/vehicleVariantsSaga';
import {
    addNewContextSagaNeo,
    createDeliverableSagaNeo,
    deleteDeliverableSagaNeo,
    deleteDeliverableVersionSagaNeo,
    fetchAllDeliverableIdsSagaNeo,
    fetchDeliverablesUserInfoSagaNeo,
    fetchDeliverableWhitelistingSaga,
    fetchFilteredDeliverableIdsSagaNeo,
    fetchFilteredDeliverablesSagaNeo,
    fetchLiveDeliverablesSaga,
    updateDeliverableSaga,
} from '~/features/deliverables/sagas/deliverableSaga';
import {
    showDeliverableDeletionDialogSagaNeo,
    showDeliverableEditorDialogSagaNeo,
    showDeliverableFileDeletionDialogSagaNeo,
    showDeliverableVersionDeletionDialogSagaNeo,
} from '~/features/deliverables/sagas/deliverableEditorSaga';
import { showDeliverableVersionEditorDialogSaga } from '~/features/deliverables/sagas/deliverableVersionEditorSaga';
import { uploadDeliverableFileSagaNeo } from '~/features/deliverables/sagas/deliverableFileSaga';
import {
    showArtifactDeletionDialogSaga,
    showArtifactEditorDialogSaga,
    showArtifactFileDeletionDialogSaga,
} from '~/features/deliverables/features/artifacts/sagas/artifactEditorSaga';
import {
    createArtifactSaga,
    deleteArtifactSaga,
    fetchFilteredArtifactsSaga,
} from '~/features/deliverables/features/artifacts/sagas/artifactSaga';
import {
    deleteArtifactFileSaga,
    downloadArtifactFileSaga,
    fetchArtifactFilesSaga,
    uploadArtifactFileSaga,
} from '~/features/deliverables/features/artifacts/sagas/artifactFileSaga';
import {
    addDeviceToDeliverableWhitelistSaga,
    removeDeviceFromDeliverableWhitelistSaga,
    revokeUninstallPackageDeliverableSaga,
    showControlDeviceQuickWhitelistingSaga,
} from '~/features/devices/sagas/controlDeviceQuickWhitelistingSaga';
import {
    fetchContextDetailsSagaNeo,
    showDeliverablePermissionDialogSagaNeo,
    updateContextOwnerSagaNeo,
} from '~/features/deliverables/sagas/deliverablePermissionSaga';
import {
    fetchControlDeviceReferencedDeliverablesSaga,
    showControlDeviceAssociatedDeliverablesSaga,
} from '~/features/devices/sagas/controlDeviceAssociatedDeliverablesSaga';
import { fetchDefaultNetworkCodeListSaga } from '~/features/base/sagas/ui/fetchNetworkCodeListSaga';
import {
    fetchDeviceCountsByCountriesSaga,
    fetchDeviceCountsByVehiclesSaga,
} from '~/features/devices/sagas/deviceDistributionsSaga';
import { fetchDeliverableHistorySaga } from '~/features/deliverables/sagas/deliverableHistorySaga';

const sagas = [
    // data
    fetchUserSaga,
    showMissingUserRoleWarningDialogSaga,
    fetchControlDeviceCountSaga,
    fetchTestDeviceCountSaga,
    fetchBlockedDeviceCountSaga,
    fetchWarningDeviceCountSaga,
    fetchErrorDeviceCountSaga,
    fetchInCustomerFleetCountSaga,
    fetchControlDeviceCountsByHwVariantSaga,
    fetchControlDeviceCountsByHwVariantInCustomerFleetSaga,
    fetchFilteredControlDevicesSaga,
    fetchSerialNumbersOfGroupSaga,
    fetchDeliverableWhitelistingSaga,
    fetchControlDeviceSaga,
    createControlDeviceSaga,
    updateControlDeviceSaga,
    triggerCheckForUpdateOnControlDeviceSaga,
    fetchControlDeviceInfoSaga,
    showControlDeviceDetailsInSidebar,
    fetchControlDeviceEligibleDeliverablesVersionsSaga,
    fetchControlDeviceOperationsSaga,
    fetchControlDeviceRolloutStatesSaga,
    fetchHwVariantsSaga,
    fetchDeviceStatusNamesSaga,
    fetchDeviceStatusTypesSaga,
    fetchVehicleSparePartNumbersSaga,
    fetchVehicleVariantsSaga,
    fetchDeliverableHistorySaga,
    fetchStateNotificationsOfDeviceSaga,
    requestNewStateNotificationSaga,
    setSystemStateColumnSettingsSaga,
    fetchCm4gCountSaga,
    fetchCm4gsSaga,
    fetchCm4gDeliverableCountSaga,
    fetchFileDeliverableCountSaga,
    fetchFilteredGroupsSaga,
    fetchDeviceGroupsSaga,
    fetchGroupSaga,
    fetchReferencesOfGroupSaga,
    createGroupSaga,
    updateGroupSaga,
    addDeviceToGroupSaga,
    removeDeviceFromGroupSaga,
    deleteGroupSaga,
    updateDeviceGroupsSaga,
    fetchUpdateEventCountSaga,
    fetchUpdateEventSuccessAndFailedSaga,
    fetchUpdateEventCountsByTimeIntervalSaga,
    fetchUpdateEventReportSaga,
    fetchFilteredUpdateEventsSaga,
    exportFilteredUpdateEventsSaga,
    fetchFilteredDeliverablesSagaNeo,
    fetchFilteredDeliverableIdsSagaNeo,
    fetchAllDeliverableIdsSagaNeo,
    uploadDeliverableFileSagaNeo,
    createDeliverableSagaNeo,
    updateDeliverableSaga,
    deleteDeliverableSagaNeo,
    deleteDeliverableVersionSagaNeo,
    fetchDeliverablesUserInfoSagaNeo,
    addNewContextSagaNeo,
    fetchLiveDeliverablesSaga,
    fetchAppsSaga,
    uploadRequestWatcherSaga,
    // ui
    followRouteSaga,
    triggerDataFetcherSaga,
    exportDevicesSaga,
    fetchHomeDataSaga,
    fetchDeviceCountsByCountriesSaga,
    fetchDeviceCountsByVehiclesSaga,
    showArtifactEditorDialogSaga,
    showArtifactDeletionDialogSaga,
    showArtifactFileDeletionDialogSaga,
    createArtifactSaga,
    deleteArtifactSaga,
    fetchFilteredArtifactsSaga,
    uploadArtifactFileSaga,
    fetchArtifactFilesSaga,
    deleteArtifactFileSaga,
    downloadArtifactFileSaga,
    showControlDeviceEditorSaga,
    addDeviceToDeliverableWhitelistSaga,
    removeDeviceFromDeliverableWhitelistSaga,
    revokeUninstallPackageDeliverableSaga,
    showControlDeviceQuickWhitelistingSaga,
    showControlDeviceAssociatedDeliverablesSaga,
    fetchControlDeviceReferencedDeliverablesSaga,
    showErrorMessagesSaga,
    showGroupEditorDialogSaga,
    showGroupDeletionDialogSaga,
    fetchSerialNumbersAndOpenEditorOfGroupSaga,
    showFileEditorDialogSaga,
    showFileDeletionDialogSaga,
    showDeliverableEditorDialogSagaNeo,
    showDeliverableVersionEditorDialogSaga,
    showDeliverableDeletionDialogSagaNeo,
    showDeliverableVersionDeletionDialogSagaNeo,
    showDeliverableFileDeletionDialogSagaNeo,
    showDeliverablePermissionDialogSagaNeo,
    updateContextOwnerSagaNeo,
    fetchContextDetailsSagaNeo,
    showUpdateEventReportDialogSaga,
    showJiraIssueEditorSaga,
    findJiraIssuesSaga,
    createJiraIssueSaga,
    fetchDefaultNetworkCodeListSaga,
];

export default sagas;
