export const CM4 = 'CM4';

export const deviceTypes = {
    [CM4]: {
        id: CM4,
        label: 'intl-msg:deviceType.CM4.label',
        tooltip: 'intl-msg:deviceType.CM4.tooltip',
        className: 'label-info',
    },
};
