import toPairs from 'lodash/fp/toPairs';
import map from 'lodash/fp/map';
import without from 'lodash/fp/without';
import keys from 'lodash/fp/keys';
import omit from 'lodash/fp/omit';

import { toShortDistroVersion, toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';

import { colors, colorsChart, otherColor, otherColorChart } from '~/features/base/constants/chartColors';
import { getVital7Keys, updateSnapshot } from '~/features/base/utils/chartUtils';

const generatePerVersion = (snapshot, filterProp) => {
    const baselineSnapshot = getVital7Keys(snapshot);
    const result = omit(['date', 'total'], updateSnapshot(baselineSnapshot, snapshot));
    const finalStuff = map(([index, [label, value]]) => ({
        label,
        value,
        filters: {
            [filterProp]: label,
            excludeDistroVersions: label === 'other' ? without(['unknown'], keys(baselineSnapshot)) : undefined,
        },
        color: label === 'other' ? otherColor : colors[index],
        colorChart: label === 'other' ? otherColorChart : colorsChart[index],
    }), toPairs(toPairs(result)));
    return finalStuff;
};

export const parseControlDevice = controlDevice => {
    const shortLastDistroVersionReported = toShortDistroVersion(controlDevice.lastDistroVersionReported);
    const shortLastBaseSoftwareVersionReported = toShortSemanticVersion(controlDevice.lastBaseSoftwareVersionReported);
    return {
        ...controlDevice,
        shortLastDistroVersionReported,
        shortLastBaseSoftwareVersionReported,
    };
};
export const parseControlDevices = controlDevices => map(parseControlDevice, controlDevices);


export const generatePerHwVariant = snapshot => generatePerVersion(snapshot, 'hwVariantList');
