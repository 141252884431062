import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { canManageDeliverablesSelector } from '~/features/user/selectors/permissionSelectors';
import { showDeliverablePermissionsDialog } from '~/features/deliverables/actions/deliverableActions';

export class ArtifactListToolbar extends PureComponent {
    render() {
        let createButton, permissionsButton;
        if (this.props.canManageArtifacts) {
            createButton = this.renderCreateButton();
            permissionsButton = this.renderPermissionsButton();
        }
        return (
            <div className='row file-deliverable-list-toolbar'>
                <div className='col-xs-6'>
                </div>
                <div className='col-xs-6'>
                    <div className='btn-toolbar pull-right'>
                        {createButton}
                        {/*{permissionsButton}*/}
                    </div>
                </div>
            </div>
        );
    }

    renderCreateButton() {
        return (
            <a id='create-deliverable-button'
               className='btn btn-default'
               onClick={this.props.onCreateArtifact}
               disabled>
                <span className='rioglyph rioglyph-plus' aria-hidden='true'></span>&nbsp;
                <FormattedMessage id='intl-msg:createArtifact'/>
            </a>
        );
    }

    renderPermissionsButton() {
        return (
            <a id='grant-permissions-button'
               className='btn btn-default'
               onClick={this.props.onDeliverablePermissionsDialog}>
                <span className='rioglyph rioglyph-user' aria-hidden='true'></span>&nbsp;
                <FormattedMessage id='intl-msg:permissionsDeliverables'/>
            </a>
        );
    }
}

export const mapStateToProps = (state) => ({
    canManageArtifacts: canManageDeliverablesSelector(state),
});

export const mapDispatchToProps = (dispatch) => {
    return {
        onDeliverablePermissionsDialog: () => {
            dispatch(showDeliverablePermissionsDialog());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtifactListToolbar);

ArtifactListToolbar.defaultProps = {
    // props
    canManageArtifacts: true,
    // functions
    onCreateArtifact: noop,
    onDeliverablePermissionsDialog: noop,
};

ArtifactListToolbar.propTypes = {
    // props
    canManageArtifacts: PropTypes.bool,
    // functions
    onCreateArtifact: PropTypes.func,
    onDeliverablePermissionsDialog: PropTypes.func,
};
