import {env} from '~/env';
import {routeStorage} from '~/features/login/storage';
import {reportErrorToSentry} from './sentry';
import {trace} from "~/setup/trace";

const {runtimeConfig} = env;


const redirectUrl = (runtimeConfig.login.redirectUri || runtimeConfig.login.silentRedirectUri).replace('.html', '')

export const attemptInitialSignIn = async userManager => {
    const isRedirectedFromLogin = window.location.href.includes(redirectUrl);
    isRedirectedFromLogin ? doSigningCallback(userManager) : doSigningSilent(userManager)
};

export const doSigningCallback = async userManager => {
    const runsInIframe = window && window.parent && window.parent !== window;
    try {
        trace('oidc.signingCallback', 'running in frame', runsInIframe);
        runsInIframe ? userManager.signinSilentCallback() : userManager.signinRedirectCallback();
    } catch (error) {
        trace('oidc.signinSilent.error', 'redirect prevented due to supsicious signin error', error);
        routeStorage.discardRoute();
        reportErrorToSentry(error);
    }
    changeUrlToRootAfterSuccessfulLogin()
}

export const doSigningSilent = async userManager => {
    try {
        trace('oidc.signinSilent');
        await userManager.signinSilent();
        routeStorage.discardRoute();
    } catch (error) {
        trace('oidc.signinSilent failed, trying page redirect...', error);
        if (env.runtimeConfig.login.preventRedirect) {
            // eslint-disable-next-line no-console
            console.warn('[feature/login] redirect prevented due to config. Error was', error);
        } else {
            saveCurrentRoute();
            userManager.signinRedirect();
        }

        trace('auth problem?', error);
        throw new Error('Need to sign in');
    }
}

const saveCurrentRoute = () => {
    const initialRoute = [window.location.hash, window.location.search].join('').replace(/^#/u, '');
    routeStorage.saveRoute(initialRoute);
};

const changeUrlToRootAfterSuccessfulLogin = () => {
    window.history.replaceState({}, document.title, window.location.origin);
}