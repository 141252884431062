import noop from 'lodash/fp/noop';
import map from 'lodash/fp/map';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import semver from 'semver';
import { connect } from 'react-redux';
import { triggerCheckForUpdateOnControlDevice } from '~/features/devices/actions/controlDeviceActions';
import { showControlDeviceEditor } from '~/features/devices/actions/controlDeviceEditorActions';
import { showJiraIssueEditor } from '~/features/atlassian/actions/atlassianJiraActions';
import { showControlDeviceQuickWhitelisting } from '~/features/devices/actions/controlDeviceQuickWhitelistingActions';
import { canQuickWhitelistControlDevicesSelector } from '~/features/devices/selectors/controlDeviceSelectors';
import {
    showControlDeviceAssociatedDeliverables,
} from '~/features/devices/actions/controlDeviceAssociatedDeliverablesActions';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import MenuItemWithTooltip from '~/features/base/components/MenuItemWithTooltip';
import { CM4 } from '~/features/devices/constants/deviceTypes';

/**
 * Control device details toolbar component
 */
const MIN_MDMC_VERSION = '8.6.0';

export class DeviceDetailsToolbar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dropDownOpen: false,
        };
    }

    onEditDevice = () => {
        const { serialNumber } = this.props;
        this.props.showControlDeviceEditor({
            isNew: false,
            serialNumber,
        });
    };

    assembleDataDogURL = (controlDeviceInfo) => {
        let query = controlDeviceInfo.serialNumber;
        query += ` OR ${controlDeviceInfo.serialNumber.replace('-', '')}`;
        if (controlDeviceInfo.type === CM4){
            query += ` OR ${controlDeviceInfo.serialNumber.replace('cm4_', '')}`;
        }
        if (controlDeviceInfo.assetId) {
            query += ` OR ${controlDeviceInfo.assetId}`;
        }
        if (controlDeviceInfo.vin) {
            query += ` OR ${controlDeviceInfo.vin}`;
        }
        return `https://app.datadoghq.eu/logs?cols=core_host%2Ccore_service&index=&live=true&messageDisplay=inline&query=${query}&stream_sort=desc`;
    };

    render() {
        const { canQuickWhitelistControlDevices, controlDeviceInfo, packageMdmcAppVersion, serialNumber } = this.props;
        const uninstallAllowed = !!packageMdmcAppVersion && semver.gte(packageMdmcAppVersion, MIN_MDMC_VERSION);
        const dropDownItems = [];
        const tooltip = <>
            <FormattedMessage id='intl-msg:deviceDetails:dropdown:tooltipTitle'/>
            <div className={'tooltipContent text-size-12'}>
                <FormattedMessage id='intl-msg:deviceDetails:dropdown:tooltipContent'/>
            </div>
        </>;

        dropDownItems.push(
            {
                value: <div>
                    <span className='rioglyph rioglyph-pencil padding-right-20 text-size-20' aria-hidden='true'></span>
                    <FormattedMessage id='intl-msg:editControlDevice'/>...
                </div>,
                onSelect: value => this.onEditDevice(),
            },
        );


        const closeDropdown = () => {
            this.setState({ dropDownOpen: false });
        };
        const customDropdown = <>
            {map((item, index) => {
                return <MenuItemWithTooltip {...item} index={index} closeDropdown={closeDropdown}/>;
            }, dropDownItems)}
        </>;

        return (
            <div className='device-details-toolbar margin-bottom-25 padding-top-10'>
                <div className='pull-right'>
                    <ButtonDropdown
                        title={<FormattedMessage id='intl-msg:moreActions'/>}
                        customDropdown={customDropdown}
                        dropdownClassName={'width-250'}
                        open={this.state.dropDownOpen}
                        onOpen={() => this.setState({ dropDownOpen: true })}
                        onClose={closeDropdown}
                    />
                </div>
            </div>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        canQuickWhitelistControlDevices: canQuickWhitelistControlDevicesSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        triggerCheckForUpdateOnControlDevice: (serialNumber) => {
            dispatch(triggerCheckForUpdateOnControlDevice({ serialNumber }));
        },
        showControlDeviceEditor: payload => {
            dispatch(showControlDeviceEditor(payload));
        },
        showJiraIssueEditor: (controlDevice) => {
            dispatch(showJiraIssueEditor({ controlDevice }));
        },
        showControlDeviceQuickWhitelisting: (serialNumber, mode, deviceType) => {
            dispatch(showControlDeviceQuickWhitelisting(serialNumber, mode, deviceType));
        },
        showControlDeviceAssociatedDeliverables: (serialNumber) => {
            dispatch(showControlDeviceAssociatedDeliverables(serialNumber));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetailsToolbar);

DeviceDetailsToolbar.defaultProps = {
    // props
    controlDeviceInfo: null,
    serialNumber: '',
    packageMdmcAppVersion: '',
    dropDownOpen: false,
    canEditServiceLogLevels: false,
    canUpdateControlDevices: false,
    // functions
    showControlDeviceEditor: noop,
    triggerCheckForUpdateOnControlDevice: noop,
    showJiraIssueEditor: noop,
    showControlDeviceQuickWhitelisting: noop,
    showControlDeviceAssociatedDeliverables: noop,
};

DeviceDetailsToolbar.propTypes = {
    // props
    controlDeviceInfo: PropTypes.object,
    serialNumber: PropTypes.string,
    packageMdmcAppVersion: PropTypes.string,
    dropDownOpen: PropTypes.bool,
    canEditServiceLogLevels: PropTypes.bool,
    canUpdateControlDevices: PropTypes.bool,
    // functions
    showControlDeviceEditor: PropTypes.func,
    triggerCheckForUpdateOnControlDevice: PropTypes.func,
    showJiraIssueEditor: PropTypes.func,
    showControlDeviceQuickWhitelisting: PropTypes.func,
    showControlDeviceAssociatedDeliverables: PropTypes.func,
};
