import isEmpty from 'lodash/fp/isEmpty';
import size from 'lodash/fp/size';

import moment from 'moment';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { changeOperations } from '~/features/deliverables/features/distros/constants/changeOperations';

import { followRoute } from '~/features/base/actions/ui/routeActions';

import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';

import { fetchDeliverableHistory } from '~/features/deliverables/actions/deliverableActions';
import DeliverableHistoryTable from '~/features/deliverables/components/DeliverableHistoryTable';
import noop from 'lodash/fp/noop';
import { deliverableColumnDescriptors } from '~/features/deliverables/constants/deliverableColumnDescriptors';
import { deliverableHistorySelector } from '~/features/deliverables/selectors/deliverableSelectors';

/**
 * Container for the software version details
 */
export class DeliverableHistoryContainer extends PureComponent {

    constructor(props) {
        super(props);
        this.onShowPage = this.onShowPage.bind(this);
        this.state = { page: 0 };
    }

    onShowPage(page) {
        this.setState({ page });
    }

    render() {
        const table = this.renderTable();
        return (
            <div className='padding-top-20 padding-bottom-20'>
                {table}
            </div>
        );
    }

    renderTable() {
        const { deliverableHistory, deliverableType } = this.props;
        if (isEmpty(deliverableHistory.deliverableVersions)) {
            return <FormattedMessage id='intl-msg:noHistory'/>;
        }
        const deliverableHistoryNewData = size(deliverableHistory.deliverableVersions) >= 1 ?
            deliverableHistory.deliverableVersions[0] : null;
        const deliverableHistoryOldData = size(deliverableHistory.deliverableVersions) === 2 ?
            deliverableHistory.deliverableVersions[1] : null;

        const timeChanged = moment(deliverableHistory.changeTimestamp).format('DD-MM-YYYY HH:mm');
        return (
            <div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='margin-left-10 margin-bottom-15'>
                            <h3>
                                <FormattedMessage
                                    id={changeOperations[deliverableHistory.changeOperation].label}/>
                                &nbsp;
                                <FormattedMessage id='intl-msg:changedByAt' values={{
                                    changedBy: deliverableHistory.changedBy,
                                    timeChanged: timeChanged,
                                }}/>
                            </h3>
                        </div>
                    </div>
                </div>
                <DeliverableHistoryTable
                    deliverable={deliverableHistoryNewData}
                    deliverableHistory={deliverableHistoryOldData}
                    rowDescriptors={deliverableColumnDescriptors[deliverableType]}
                    activePage={deliverableHistory.page}
                    pageCount={deliverableHistory.pageCount}
                    onShowPage={this.onShowPage}/>
            </div>
        );
    }

    fetchData() {
        const { deliverableVersion, deliverableId, deliverableType } = this.props;
        const { page } = this.state;
        this.props.fetchDeliverableHistory({
            deliverableVersion,
            deliverableId,
            deliverableType,
            page: (page && page > 0) ? (page - 1) : 0,
        });
    }

    componentWillMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.page !== this.state.page) {
            this.fetchData();
        }
    }
}

export const mapStateToProps = (state) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        deliverableHistory: deliverableHistorySelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
        fetchDeliverableHistory: payload => {
            dispatch(fetchDeliverableHistory(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableHistoryContainer);

DeliverableHistoryContainer.defaultProps = {
    // props
    deliverableHistory: {
        deliverableVersions: [],
        page: 0,
        pageCount: 1,
    },
    // functions
    fetchDeliverableHistory: noop,
};

DeliverableHistoryContainer.propTypes = {
    // props
    pathname: PropTypes.string,
    search: PropTypes.string,
    deliverableHistory: PropTypes.object,
    // functions
    fetchDeliverableHistory: PropTypes.func,
};
