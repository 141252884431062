import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import {
    FETCH_CM4G_DELIVERABLE_COUNT,
} from '~/features/deliverables/features/packages/actions/packageDeliverableActions';
import { mergeAggregation } from '~/features/higherorder/actions/aggregationActions';

import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';

import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';

import { doHandleErrorSaga, postHTTP } from '~/features/base/sagas/sagaUtil';

import { ENTITY_CM4G_DELIVERABLE } from '~/features/base/constants/entities';
import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { DeliverableType } from '~/features/deliverables/constants/DeliverableType';

export function* getDeliverablesURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/deliverable`;
}

export function* doFetchCm4gDeliverableCountSaga(action) {
    try {
        const url = yield call(getDeliverablesURL);
        const response = yield call(postHTTP, `${url}/count`, JSON.stringify({ deliverableType: DeliverableType.CM4G }));
        yield put(mergeAggregation({
            entityName: ENTITY_CM4G_DELIVERABLE,
            scope: 'totalCount',
        }, response.count));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}





export function* fetchCm4gDeliverableCountSaga() {
    yield takeLatest(FETCH_CM4G_DELIVERABLE_COUNT, doFetchCm4gDeliverableCountSaga);
}



