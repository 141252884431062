import defaults from 'lodash/fp/defaults';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { ALL, createDeliverablesVersionOptions } from '~/features/base/constants/filterOptions';

import { followRoute } from '~/features/base/actions/ui/routeActions';

import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import SerialNumberFormItem from '~/features/base/components/forms/SerialNumberFormItem';
import ResultFormItem from '~/features/base/components/forms/ResultFormItem';
import FromToFormItem from '~/features/base/components/forms/FromToFormItem';

import { parseQuery } from '~/features/base/utils/query';
import { filterDeliverableVersionsSelector } from '~/features/base/selectors/filterSelectors';
import SuggestFormItem from '~/features/base/components/forms/SuggestFormItem';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';

const defaultFilters = {
    result: ALL,
    from: undefined,
    to: undefined,
    showOnlyLastEvent: undefined,
};

export class UpdateEventsFiltersContainer extends PureComponent {
    constructor(props) {
        super(props);
        props.fetchPackages();
        const parsed = parseQuery(props.search);
        if (parsed !== undefined && parsed.deliverableId) {
            props.fetchPackageVersions(parsed.deliverableId);
        }
    }

    onSerialNumberChange = ({ serialNumber }) => {
        this.updateFilter({
            serialNumber,
        });
    };
    onDeliverableIdChange = ({ deliverableId }) => {
        this.props.fetchPackageVersions(deliverableId);
        this.updateFilter({
            deliverableId,
            targetDeliverableVersion: undefined,
        });
    };
    onTargetDeliverableVersionChange = ({ suggestionId }) => {
        this.updateFilter({
            targetDeliverableVersion: suggestionId || undefined,
        });
    };
    onShowOnlyLastEventChange = (event) => {
        this.updateFilter({
            showOnlyLastEvent: event.currentTarget.checked,
        });
    };
    onResultChange = ({ result }) => {
        this.updateFilter({
            result,
        });
    };
    onFromToChange = ({ from, to }) => {
        this.updateFilter({
            from,
            to,
        });
    };
    clearFilters = () => {
        this.updateFilter({
            deliverableId: undefined,
            result: undefined,
            from: undefined,
            to: undefined,
            targetDeliverableVersion: undefined,
        });
    };

    updateFilter(filters) {
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                ...filters,
                page: 1,
            },
        });
    }

    render() {
        const {
            search,
            serialNumber,
            serialNumberInputVisible,
            deliverableId,
            targetDeliverableVersion,
            targetDeliverableVersionInputVisible,
            packageDeliverableVersions,
        } = this.props;

        const parsedQuery = parseQuery(search);
        const defaultedQuery = defaults(defaultFilters, parsedQuery);
        const fullQuery = defaults(defaultedQuery, {
            serialNumber,
            deliverableId,
            targetDeliverableVersion,
        });
        // Filters:
        // hwSerial
        // deliverableId
        // targetDeliverableVersion
        // outcome --> result        DONE
        // from/to                   DONE
        const packageVersionsOptions = createDeliverablesVersionOptions(packageDeliverableVersions);
        return (
            <div className='margin-20'>
                {
                    serialNumberInputVisible ?
                        <SerialNumberFormItem
                            value={fullQuery.serialNumber}
                            onChange={this.onSerialNumberChange}/> : null
                }
                {
                    fullQuery.deliverableId && targetDeliverableVersionInputVisible ?
                        <SuggestFormItem value={fullQuery.targetDeliverableVersion}
                                         label='intl-msg:deliverableVersion'
                                         options={packageVersionsOptions}
                                         onChange={this.onTargetDeliverableVersionChange}/> : null
                }
                <ResultFormItem value={fullQuery.result} onChange={this.onResultChange}/>
                <Checkbox className='margin-10' onClick={this.onShowOnlyLastEventChange}
                          checked={fullQuery.showOnlyLastEvent} size='large'>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:showLatestUpdateEvents.label'/>
                    </label>
                </Checkbox>
                <FromToFormItem inputClassName='filter-input'
                                from={fullQuery.from}
                                to={fullQuery.to}
                                onChange={this.onFromToChange}/>
                <div className='form-group pull-right margin-top-15'>
                    <a id='clear-filters-button'
                       className='btn btn-default'
                       onClick={this.clearFilters}>
                        <span className='rioglyph rioglyph-trash' aria-hidden='true'></span>&nbsp;
                        <FormattedMessage id='intl-msg:clearFilters'/>
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    pathname: pathnameSelector(state),
    search: searchSelector(state),
    packageDeliverableVersions: filterDeliverableVersionsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    followRoute: (options) => {
        dispatch(followRoute(options));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEventsFiltersContainer);

UpdateEventsFiltersContainer.defaultProps = {
    // props
    serialNumberInputVisible: true,
    deliverableIdInputVisible: true,
    showOnlyLastEvent: true,
    targetDeliverableVersionInputVisible: true,
    pathname: '',
    search: '',
    packages: [],
    packageDeliverableVersions: [],
    // functions
    followRoute: noop,
};
UpdateEventsFiltersContainer.propTypes = {
    // props
    serialNumber: PropTypes.string,
    serialNumberInputVisible: PropTypes.bool,
    deliverableId: PropTypes.string,
    deliverableIdInputVisible: PropTypes.bool,
    showOnlyLastEvent: PropTypes.bool,
    targetDeliverableVersion: PropTypes.string,
    targetDeliverableVersionInputVisible: PropTypes.bool,
    pathname: PropTypes.string,
    search: PropTypes.string,
    packages: PropTypes.array,
    packageDeliverableVersions: PropTypes.array,
    // functions
    followRoute: PropTypes.func,
};
