import { createApi, } from '@reduxjs/toolkit/query/react';
import { dynamicBaseQuery } from '~/api/common/dynamicBaseQuery';
import { DeliverableSearchCriteria } from '~/api/models/deliverable-search-criteria';
import { SemanticVersion } from '~/api/models';
import { PersistedDeliverable } from '~/api/models/persisted-deliverable';

const baseUrl: string = '/v1/admin/deliverable';

export const deliverableManagementApi = createApi({
    reducerPath: 'deliverableManagementApi',
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({

        fetchDeliverableVersions: builder.query<{ content: SemanticVersion[] }, DeliverableVersionRequestModel>({
            query: ({deliverableType, deliverableId}) => ({
                url: `${baseUrl}/${deliverableType}/${deliverableId}/versions`,
                method: 'GET',
            }),
        }),
        fetchDeliverableIds: builder.query<{ content: string[] }, DeliverableSearchCriteria>({
            query: (criteria: DeliverableSearchCriteria) => ({
                url: `${baseUrl}/search/deliverable-ids?page=0&size=1000`,
                method: 'POST',
                body: criteria
            }),
        }),
        validateDeliverable: builder.query<{ content: string[] }, { deliverable: PersistedDeliverable, isEdit: boolean }>({
            query: (body) => ({
                url: `${baseUrl}/validate-for-create`,
                method: 'POST',
                body: body.deliverable
            }),
            serializeQueryArgs: ({queryArgs: {deliverable, isEdit}}) => ({
                deliverableId: deliverable?.deliverableId,
                deliverableVersion: deliverable?.deliverableVersion,
                isEdit
            }),
            forceRefetch({currentArg, previousArg}) {
                return currentArg !== previousArg;
            },
        }),

    }),
});

export const {
    useLazyFetchDeliverableVersionsQuery,
    useFetchDeliverableIdsQuery,
    useValidateDeliverableQuery,
    useLazyValidateDeliverableQuery
} = deliverableManagementApi;
