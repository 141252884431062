import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import uid from '~/features/base/utils/uid';
import { fetchControlDeviceRolloutStates } from '~/features/devices/actions/ui/controlDeviceDetailsActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { entitiesOfDeviceSelector } from '~/features/devices/selectors/controlDeviceRolloutStatusSelectors';
import map from 'lodash/fp/map';
import orderBy from 'lodash/fp/orderBy';
import { FormattedMessage, FormattedTime } from 'react-intl';
import classNames from 'classnames';
import {
    ASSIGNED,
    BUNDLE_INSTALL,
    BUNDLE_UPDATE,
    DELIVERABLE_INSTALL,
    DELIVERABLE_UPDATE,
    DOWNLOAD_FINISHED,
    DOWNLOAD_IN_PROGRESS,
    FAILED,
    FILE_DOWNLOAD,
    FINISHED,
    INSTALLATION_FINISHED,
    INSTALLATION_IN_PROGRESS,
    REBOOT_PENDING,
    rolloutStatus,
    SENT_TO_DEVICE,
    SUCCESS,
    updateEventType,
    updateOutcome,
} from '~/features/devices/constants/deviceRolloutStates';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import SteppedProgressBar from '@rio-cloud/rio-uikit/lib/es/SteppedProgressBar';
import { CM4 } from '~/features/devices/constants/deviceTypes';

/**
 * Container for the device rollout states
 */
const FETCH_ROLLOUT_STATES_LIMIT = 10;
const appProgressStates = [
    SENT_TO_DEVICE,
    DOWNLOAD_IN_PROGRESS,
    DOWNLOAD_FINISHED,
    INSTALLATION_IN_PROGRESS,
    INSTALLATION_FINISHED,
    FINISHED,
];



//CM4G
const deliverableProgressStates = [
    SENT_TO_DEVICE,
    DOWNLOAD_IN_PROGRESS,
    DOWNLOAD_FINISHED,
    INSTALLATION_IN_PROGRESS,
    REBOOT_PENDING,
    INSTALLATION_FINISHED,
    FINISHED,
];

const bundleProgressStates = [
    SENT_TO_DEVICE,
    DOWNLOAD_IN_PROGRESS,
    DOWNLOAD_FINISHED,
    INSTALLATION_IN_PROGRESS,
    REBOOT_PENDING,
    INSTALLATION_FINISHED,
    FINISHED,
];

const fileProgressStates = [
    SENT_TO_DEVICE,
    DOWNLOAD_IN_PROGRESS,
    DOWNLOAD_FINISHED,
    FINISHED,
];

export class DeviceRolloutStatesContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
    }

    getLastStep = (rolloutState) => {
        switch (rolloutState.type) {
            case DELIVERABLE_INSTALL:
                return deliverableProgressStates.length - 1;
            case DELIVERABLE_UPDATE:
                return deliverableProgressStates.length - 1;
            case BUNDLE_INSTALL:
                return bundleProgressStates.length - 1;
            case BUNDLE_UPDATE:
                return bundleProgressStates.length - 1;
            case FILE_DOWNLOAD:
                return fileProgressStates.length - 1;
            default:
                return 5;
        }
    };

    getStepNumber = (rolloutState) => {
        if (rolloutState.isFinished && rolloutState.outcome === SUCCESS) {
            return this.getLastStep(rolloutState);
        }

        if (rolloutState.isFinished && rolloutState.outcome === FAILED) {
            return -1;
        }
        const lastEvent = rolloutState.eventSteps.pop();
        if (!lastEvent) {
            return 0;
        }

        const { deviceType } = this.props;

        switch (lastEvent.state) {
            case ASSIGNED:
                return -1;
            case SENT_TO_DEVICE:
                return 0;
            case DOWNLOAD_IN_PROGRESS:
                return 1;
            case DOWNLOAD_FINISHED:
                return 2;
            case INSTALLATION_IN_PROGRESS:
                return 3;
            case REBOOT_PENDING:
                return 4;
            case INSTALLATION_FINISHED:
                if (deviceType === CM4) return 5;
                else return 4;
            default:
                return 0;
        }
    };

    getStateLabelDefinition = (state, rolloutState) => {
        const currentStep = rolloutState.eventSteps && rolloutState.eventSteps.find(
            eventStep => eventStep.state === state);
        const currentStepInfo = (currentStep && currentStep.info) || '';
        const currentStepDate = (currentStep && currentStep.timestamp);
        return {
            icon: currentStepInfo ? <span>
                    <OverlayTrigger placement='top'
                                    overlay={currentStepInfo && <Tooltip id='tooltip' className='top-right'>
                                        {currentStepInfo}
                                    </Tooltip>}>
                        {rolloutStatus[state].icon}
                        </OverlayTrigger>
                </span> : rolloutStatus[state].icon,
            label: <div>
                <div><FormattedMessage id={rolloutStatus[state].label}/></div>
                {
                    currentStepDate && <div>
                        <FormattedTime value={currentStepDate} hour='numeric' minute='numeric' second='numeric'/>
                    </div>
                }
            </div>,
        };
    };

    compileAppProgressBar = (rolloutState) => {
        const stateLabelDefinitions = [];
        switch (rolloutState.type) {
            case FILE_DOWNLOAD:
                fileProgressStates.map(state => {
                    stateLabelDefinitions.push(this.getStateLabelDefinition(state, rolloutState));
                });
                break;
            case DELIVERABLE_UPDATE:
                deliverableProgressStates.map(state => {
                    stateLabelDefinitions.push(this.getStateLabelDefinition(state, rolloutState));
                });
                break;
            case DELIVERABLE_INSTALL:
                deliverableProgressStates.map(state => {
                    stateLabelDefinitions.push(this.getStateLabelDefinition(state, rolloutState));
                });
                break
            case BUNDLE_UPDATE:
                deliverableProgressStates.map(state => {
                    stateLabelDefinitions.push(this.getStateLabelDefinition(state, rolloutState));
                });
                break;
            default:
        }
        return stateLabelDefinitions;
    };

    renderOutcome = (rolloutState) => {
        if (!rolloutState.outcome) {
            return;
        }
        const labelClass = updateOutcome[rolloutState.outcome].className;
        return (
            <span>
                <OverlayTrigger placement='top' overlay={
                    <Tooltip id='tooltip' className='top-right'>
                        <FormattedMessage id={updateOutcome[rolloutState.outcome].tooltip}/>
                    </Tooltip>}>
                    <span className={classNames('text-size-18', labelClass)}></span>
                </OverlayTrigger>
            </span>
        );
    };

    renderLabel = (rolloutState) => {
        const renderedUpdateOutcome = this.renderOutcome(rolloutState);
        const labelClass = updateEventType[rolloutState.type].className;
        return (
            <div>
                <div className='row'>
                    <span>
                        {renderedUpdateOutcome}
                    </span>
                    <span className='text-color-dark padding-left-5'>
                        <FormattedTime value={rolloutState.lastUpdated} year='numeric' month='2-digit' day='2-digit'/>
                    </span>
                </div>
                <div className='row padding-top-5 padding-left-25 justify-content-center'>
                    <div className='display-flex'>
                        <div>
                            <span className={classNames('label label-condensed margin-right-5',
                                labelClass)}>
                                <FormattedMessage id={updateEventType[rolloutState.type].label}/>
                            </span>
                        </div>
                        <div>
                            <span className='text-color-dark'>{`${rolloutState.deliverableId} - v.`}</span>
                            <span className='text-color-dark'>
                                <ShortBaseSwVersion baseSwVersion={rolloutState.version}/>
                           </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { rolloutStates } = this.props;
        return (
            <div>
                {
                    map(rolloutState => {
                        // if state is app
                        const labelProgressBar = this.compileAppProgressBar(rolloutState);
                        const selectedStep = this.getStepNumber(rolloutState);
                        const renderedLabel = this.renderLabel(rolloutState);
                        return <div className='padding-10'>
                            {renderedLabel}
                            <SteppedProgressBar
                                className='padding-top-10 padding-bottom-10 text-size-12'
                                selectedStepNumber={selectedStep}
                                onSelectedChanged={this.onSelectedChanged}
                                labels={labelProgressBar}
                            />
                        </div>;
                    }, orderBy(['lastUpdated'], ['desc'], rolloutStates))
                }
            </div>
        );
    }

    registerDataInterest() {
        const { serialNumber, limit } = this.props;
        const fetchRolloutStatesOptions = {
            serialNumber,
            limit: limit ? limit : FETCH_ROLLOUT_STATES_LIMIT,
            //scope: JSON.stringify(searchCriteria), // TESTING WITH SEARCH_CRITERIA_BASED_SCOPE
        };

        this.props.registerDataInterest(this.name, [
            fetchControlDeviceRolloutStates(fetchRolloutStatesOptions),
        ]);
    }

    componentWillMount() {
        const { autoRefresh } = this.props;
        if (autoRefresh) {
            this.registerDataInterest();
            this.props.triggerDataFetcher();
            this.interval = setInterval(() => {
                this.props.triggerDataFetcher();
            }, 20000);
        }
    }

    componentWillUnmount() {
        this.props.unregisterDataInterest(this.name);
        clearInterval(this.interval);
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        rolloutStates: entitiesOfDeviceSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceRolloutStatesContainer);

DeviceRolloutStatesContainer.defaultProps = {
    // props
    serialNumber: '',
    deviceType: '',
    autoRefresh: true,
    limit: 0,
    rolloutStates: [],
    rolloutStatesLoading: false,
    // functions
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
};

DeviceRolloutStatesContainer.propTypes = {
    // props
    serialNumber: PropTypes.string,
    deviceType: PropTypes.string,
    autoRefresh: PropTypes.bool,
    limit: PropTypes.number,
    rolloutStates: PropTypes.array,
    rolloutStatesLoading: PropTypes.bool,
    // functions
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
};
