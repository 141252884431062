import {
    ENTITY_DELIVERABLE_IDS_SCOPE_DEVICES,
    ENTITY_DELIVERABLE_SCOPE_DEVICES,
} from '~/features/base/constants/entities';

export const SHOW_DELIVERABLE_EDITOR_DIALOG = 'SHOW_DELIVERABLE_EDITOR_DIALOG_NEO';
export const SHOW_DELIVERABLE_VERSION_EDITOR_DIALOG = 'SHOW_DELIVERABLE_VERSION_EDITOR_DIALOG';
export const SHOW_DELIVERABLE_DELETION_DIALOG = 'SHOW_DELIVERABLE_DELETION_DIALOG_NEO';
export const SHOW_DELIVERABLE_VERSION_DELETION_DIALOG = 'SHOW_DELIVERABLE_VERSION_DELETION_DIALOG';
export const SHOW_DELIVERABLE_FILE_DELETION_DIALOG = 'SHOW_DELIVERABLE_FILE_DELETION_DIALOG_NEO';

export const SHOW_DELIVERABLE_PERMISSION_DIALOG_NEO = 'SHOW_DELIVERABLE_PERMISSION_DIALOG_NEO';

export const RESET_DELIVERABLE_EDITOR = 'RESET_DELIVERABLE_EDITOR_NEO';
export const RESET_DELIVERABLE_VERSION_EDITOR = 'RESET_DELIVERABLE_VERSION_EDITOR';
export const CHANGE_DELIVERABLE_EDITOR = 'CHANGE_DELIVERABLE_EDITOR_NEO';
export const CHANGE_DELIVERABLE_VERSION_EDITOR = 'CHANGE_DELIVERABLE_VERSION_EDITOR';

export const ADD_NEW_CONTEXT = 'ADD_NEW_CONTEXT_NEO';
export const UPDATE_CONTEXT_OWNER = 'UPDATE_CONTEXT_OWNER_NEO';
export const UPDATE_CONTEXT_OWNER_MODE_ADD = 'ADD_NEO';
export const UPDATE_CONTEXT_OWNER_MODE_REMOVE = 'REMOVE_NEO';
export const FETCH_CONTEXT_DETAILS = 'FETCH_CONTEXT_DETAILS_NEO';
export const FETCH_FILTERED_DELIVERABLES = 'FETCH_FILTERED_DELIVERABLES_NEO';
export const FETCH_FILTERED_DELIVERABLE_IDS = 'FETCH_FILTERED_DELIVERABLE_IDS_NEO';
export const FETCH_ALL_DELIVERABLE_IDS = 'FETCH_ALL_DELIVERABLE_IDS_NEO';
export const FETCH_DELIVERABLES_USER_INFO = 'FETCH_DELIVERABLES_USER_INFO_NEO';
export const FETCH_LIVE_DELIVERABLES = 'FETCH_LIVE_DELIVERABLES';
export const FETCH_DELIVERABLE_HISTORY = 'FETCH_DELIVERABLE_HISTORY';
export const CREATE_DELIVERABLE = 'CREATE_DELIVERABLE_NEO';
export const CREATE_DELIVERABLE_VERSION = 'CREATE_DELIVERABLE_VERSION';
export const SET_DELIVERABLES = 'SET_DELIVERABLES_NEO';
export const RESET_DELIVERABLES = 'RESET_DELIVERABLES_NEO';
export const SET_DELIVERABLE_FILES = 'SET_DELIVERABLE_FILES_NEO';
export const UPDATE_DELIVERABLE = 'UPDATE_DELIVERABLE_NEO';
export const UPDATE_DELIVERABLE_VERSION = 'UPDATE_DELIVERABLE_VERSION';
export const DELETE_DELIVERABLE = 'DELETE_DELIVERABLE_NEO';
export const DELETE_DELIVERABLE_VERSION = 'DELETE_DELIVERABLE_VERSION';

export const fetchFilteredDeliverables = ({
    scope = ENTITY_DELIVERABLE_SCOPE_DEVICES,
    page,
    size,
    searchCriteria,
}) => {
    return {
        type: FETCH_FILTERED_DELIVERABLES,
        scope,
        page,
        size,
        searchCriteria,
    };
};

export const fetchLiveDeliverables = ({ type }) => {
    return {
        type: FETCH_LIVE_DELIVERABLES,
        payload: {
            type,
        },
    };
};

export const fetchDeliverableHistory = ({ deliverableType, deliverableId, deliverableVersion, page }) => {
    return {
        type: FETCH_DELIVERABLE_HISTORY,
        payload: {
            deliverableType,
            deliverableId,
            deliverableVersion,
            page,
        },
    };
};

export const fetchFilteredDeliverableIds = ({
    scope = ENTITY_DELIVERABLE_IDS_SCOPE_DEVICES,
    page,
    size,
    deliverableIdPrefix,
    context,
    releaseState,
    searchCriteria,
}) => {
    return {
        type: FETCH_FILTERED_DELIVERABLE_IDS,
        scope,
        page,
        size,
        deliverableIdPrefix,
        context,
        releaseState,
        searchCriteria,
    };
};

export const fetchAllDeliverableIds = ({
    page,
    size,
    searchCriteria,
}) => {
    return {
        type: FETCH_ALL_DELIVERABLE_IDS,
        page,
        size,
        searchCriteria,
    };
};

export const fetchDeliverablesUserInfo = () => {
    return {
        type: FETCH_DELIVERABLES_USER_INFO,
    };
};

export function showDeliverableEditorDialog(payload) {
    return {
        type: SHOW_DELIVERABLE_EDITOR_DIALOG,
        payload,
    };
}

export function showDeliverableDeletionDialog(payload) {
    return {
        type: SHOW_DELIVERABLE_DELETION_DIALOG,
        payload,
    };
}

export function showDeliverableVersionDeletionDialog(payload) {
    return {
        type: SHOW_DELIVERABLE_VERSION_DELETION_DIALOG,
        payload,
    };
}

export function resetDeliverableEditor() {
    return {
        type: RESET_DELIVERABLE_EDITOR,
    };
}

export function changeDeliverableEditor(payload) {
    return {
        type: CHANGE_DELIVERABLE_EDITOR,
        payload,
    };
}

export function showDeliverablePermissionsDialog(payload) {
    return {
        type: SHOW_DELIVERABLE_PERMISSION_DIALOG_NEO,
        payload,
    };
}

export const addNewContext = (newContextName) => {
    return {
        type: ADD_NEW_CONTEXT,
        newContextName
    };
};

export const addContextOwner = (contextName, ownerName) => {
    return {
        type: UPDATE_CONTEXT_OWNER,
        mode: UPDATE_CONTEXT_OWNER_MODE_ADD,
        contextName,
        ownerName
    };
};

export const removeContextOwner = (contextName, ownerName) => {
    return {
        type: UPDATE_CONTEXT_OWNER,
        mode: UPDATE_CONTEXT_OWNER_MODE_REMOVE,
        contextName,
        ownerName
    };
};

export function showDeliverableVersionEditorDialog(payload) {
    return {
        type: SHOW_DELIVERABLE_VERSION_EDITOR_DIALOG,
        payload,
    };
}

export function resetDeliverableVersionEditor() {
    return {
        type: RESET_DELIVERABLE_VERSION_EDITOR,
    };
}

export function changeDeliverableVersionEditor(payload) {
    return {
        type: CHANGE_DELIVERABLE_VERSION_EDITOR,
        payload,
    };
}

export const createDeliverable = (deliverable) => {
    return {
        type: CREATE_DELIVERABLE,
        payload: deliverable,
    };
};

export const setDeliverables = (deliverableType, deliverables) => {
    return {
        type: SET_DELIVERABLES,
        deliverableType,
        deliverables,
    };
};

export const resetDeliverables = () => {
    return {
        type: RESET_DELIVERABLES,
    };
};

export const updateDeliverable = (deliverable) => {
    return {
        type: UPDATE_DELIVERABLE,
        payload: deliverable,
    };
};

export const deleteDeliverable = (deliverableId, deliverableType, deliverableVersions) => {
    return {
        type: DELETE_DELIVERABLE,
        payload: {
            deliverableId,
            deliverableType,
            deliverableVersions,
        },
    };
};

export const deleteDeliverableVersion = (deliverableId, deliverableType, deliverableVersion) => {
    return {
        type: DELETE_DELIVERABLE_VERSION,
        payload: {
            deliverableId,
            deliverableType,
            deliverableVersion,
        },
    };
};

