import isEmpty from 'lodash/fp/isEmpty';
import {
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';
import {
    DELIVERABLES_BUNDLES_PATH,
    DELIVERABLES_CM4G_PATH,
    DELIVERABLES_FILES_PATH,
} from '~/features/base/constants/routes';

export const getPathWithoutDeliverabeIdAndVersion = (pathname) => {
    let path = pathname;
    const splittedPath = path.split('/');
    if (splittedPath.length > 3) {
        path = splittedPath.splice(0, 3).join('/');
    }
    return path;
};

export const hasAccessibleContextForDeliverable = (deliverablesContexts, deliverable) => {
    if (isEmpty(deliverablesContexts)) {
        return false;
    }
    if (deliverable.context) {
        return deliverablesContexts.includes(deliverable.context);
    }
    if (deliverable.owningContext) {
        return deliverablesContexts.includes(deliverable.owningContext);
    }
};

export const getDeliverableId = (deliverableIds, deliverableId) => {
    if (!deliverableId && deliverableIds) {
        return deliverableIds[0];
    }
    return deliverableId;

};

export const isWhitelistEmpty = (whitelistInfo) => {
    if (whitelistInfo) {
        return whitelistInfo.deviceWhitelist === [] &&
            whitelistInfo.vehicleGroupWhitelist === [] &&
            whitelistInfo.deviceBlacklist === [] &&
            whitelistInfo.vehicleGroupWhitelist === [];
    } else {
        return true;
    }
};

export const getDeliverableTypePath = (deliverableType) => {
    switch (deliverableType) {
        case DELIVERABLE_TYPE_FILE:
            return DELIVERABLES_FILES_PATH;
        case DELIVERABLE_TYPE_CM4G:
            return DELIVERABLES_CM4G_PATH;
        case DELIVERABLE_TYPE_BUNDLE:
            return DELIVERABLES_BUNDLES_PATH;
        default:
            return DELIVERABLES_FILES_PATH;
    }
};
