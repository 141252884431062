import classNames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { deviceTypes } from '~/features/devices/constants/deviceTypes';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export const DeviceTypeLabel = ({ deviceType }) => {
    if (!deviceType) {
        return null;
    }
    const labelClass = deviceTypes[deviceType]?.className;
    return (
        <span>
            <OverlayTrigger placement='top' overlay={
                <Tooltip id='tooltip' className='top-right'>
                    <FormattedMessage id={deviceTypes[deviceType]?.tooltip}/>
                </Tooltip>}>
                <span className={classNames('label label-condensed margin-right-5', labelClass)}>
                    <FormattedMessage id={deviceTypes[deviceType]?.label}/>
                </span>
            </OverlayTrigger>
        </span>
    );
};

export const DeviceType = ({ controlDevice }) => {
    if (!controlDevice) {
        return null;
    }
    return <DeviceTypeLabel deviceType={controlDevice?.type}/>;
};

export default DeviceType;

DeviceType.defaultProps = {};

DeviceType.propTypes = {
    // props
    controlDevice: PropTypes.object,
};
