import { DELIVERABLE_TYPE_FILE, } from '~/features/deliverables/constants/deliverablesParameters';
import { env } from '~/env';

const deliverablesDefaultUrlsSuffix = {
    [DELIVERABLE_TYPE_FILE]: 'release/file/',

};
export const deliverablesDefaultUrl = (deliverableType: string): string => {
    const { downloadBaseUrl } = env.runtimeConfig;
    return downloadBaseUrl? ` ${downloadBaseUrl}${deliverablesDefaultUrlsSuffix[deliverableType]}` : '';
};
