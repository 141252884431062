import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import {
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';

export class DeliverableVersionEditorForm extends PureComponent {

    onDeliverableNameChange = (event) => {
        this.props.changeDeliverableEditor({
            deliverableName: event.target.value,
        });
    };


    render() {
        const { model } = this.props;
        const deliverableNameInput = this.renderDeliverableNameInput(model);

        return (
            <form>
                {deliverableNameInput}
            </form>
        );
    }

    getDeliverableNamePerType = (deliverableType) => {
        switch (deliverableType) {
            case DELIVERABLE_TYPE_FILE:
                return <FormattedMessage id="intl-msg:fileName"/>;
            case DELIVERABLE_TYPE_CM4G:
                return <FormattedMessage id="intl-msg:cm4gName"/>;
            case DELIVERABLE_TYPE_BUNDLE:
                return <FormattedMessage id="intl-msg:bundleName"/>;
            default:
                return <FormattedMessage id="intl-msg:fileName"/>;
        }
    };

    renderDeliverableNameInput(model) {
        return (
            <div className='form-group form-group-file-id'>
                <label className='control-label'>
                    {this.getDeliverableNamePerType(this.props.deliverableType)}
                </label>
                <FormattedMessage id='intl-msg:name.prompt'>
                    {placeHolder =>
                        <input type='text' className='form-control'
                               value={model.deliverableName}
                               placeholder={placeHolder}
                               onChange={this.onDeliverableNameChange}/>
                    }
                </FormattedMessage>
            </div>
        );
    }

}

export default DeliverableVersionEditorForm;

// TODO Obviously it doesn't work without knowledge about model
DeliverableVersionEditorForm.defaultProps = {
    // props
    model: {
        deliverableName: '',
        context: '',
        file: undefined,
    },
    deliverableType: '',
    // functions
    changeDeliverableEditor: noop,
};

DeliverableVersionEditorForm.propTypes = {
    // props
    model: {
        deliverableName: PropTypes.string,
        context: PropTypes.string,
        file: PropTypes.object,
    },
    deliverableType: PropTypes.string,
    // functions
    changeDeliverableEditor: PropTypes.func,
};
