import { call, put, takeLatest } from 'redux-saga/effects';

import { showModal } from '~/features/base/actions/ui/modalsActions';
import { fetchFilteredGroups } from '~/features/groups/actions/groupActions';

import { doFetchFilteredGroupsSaga } from '~/features/groups/sagas/groupSaga';
import {
    ARTIFACT_DELETE_MODAL,
    ARTIFACT_EDITOR_MODAL,
    ARTIFACT_FILE_DELETE_MODAL,
} from '~/features/base/constants/modals';
import {
    changeArtifactEditor,
    resetArtifactEditor,
    SHOW_ARTIFACT_DELETION_DIALOG,
    SHOW_ARTIFACT_EDITOR_DIALOG,
} from '~/features/deliverables/features/artifacts/actions/artifactActions';
import {
    SHOW_ARTIFACT_FILE_DELETION_DIALOG,
} from '~/features/deliverables/features/artifacts/actions/artifactFileActions';

export function* doPrepareForNewArtifactSaga(fileDeliverable) {
    yield put(changeArtifactEditor({
        isNew: true,
        ...fileDeliverable,
    }));
    // Should suggest next patch  version
}


export function* doShowArtifactEditorDialogSaga(action) {
    yield put(resetArtifactEditor());
    const { deliverableType, deliverableId, deliverableVersion, isNew } = action.payload;
    if (isNew) {
        yield call(doPrepareForNewArtifactSaga, action.payload);
        // } else {
        //     yield call(doPrepareForExistingDeliverableSaga, action.payload);
    }

    yield call(doFetchFilteredGroupsSaga, fetchFilteredGroups());

    yield put(showModal({
        modalType: ARTIFACT_EDITOR_MODAL,
        modalProps: {
            deliverableType,
            deliverableId,
            deliverableVersion,
        },
    }));
}

export function* doShowArtifactDeletionDialogSaga(action) {
    yield put(resetArtifactEditor());
    const { artifactId, artifactName } = action.payload;
    yield put(showModal({
        modalType: ARTIFACT_DELETE_MODAL,
        modalProps: {
            artifactId,
            artifactName,
        },
    }));
}

export function* doShowArtifactFileDeletionDialogSaga(action) {
    yield put(resetArtifactEditor());
    const { artifactId, fileName } = action.payload;
    yield put(showModal({
        modalType: ARTIFACT_FILE_DELETE_MODAL,
        modalProps: {
            artifactId,
            fileName,
        },
    }));
}

export function* showArtifactEditorDialogSaga() {
    yield takeLatest(SHOW_ARTIFACT_EDITOR_DIALOG, doShowArtifactEditorDialogSaga);
}

export function* showArtifactDeletionDialogSaga() {
    yield takeLatest(SHOW_ARTIFACT_DELETION_DIALOG, doShowArtifactDeletionDialogSaga);
}

export function* showArtifactFileDeletionDialogSaga() {
    yield takeLatest(SHOW_ARTIFACT_FILE_DELETION_DIALOG, doShowArtifactFileDeletionDialogSaga);
}
