import get from 'lodash/fp/get';

import { ENTITY_CM4G_DELIVERABLE } from '~/features/base/constants/entities';

import { aggregationStoreSelector } from '~/features/higherorder/selectors/dataSelectors';

export const packageDeliverableCountSelector = state => get('totalCount', packageDeliverableAggregationsSelector(state));

const cm4gDeliverableAggregationsSelector = state => aggregationStoreSelector(state)[ENTITY_CM4G_DELIVERABLE];

export const cm4gDeliverableCountSelector = state => get('totalCount', cm4gDeliverableAggregationsSelector(state));

