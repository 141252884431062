import React from 'react';
import { Route, Switch } from 'react-router-dom';
// Routes
import {
    DELIVERABLES_BUNDLES_PATH,
    DELIVERABLES_CM4G_PATH,
    DELIVERABLES_FILES_PATH,
} from '~/features/base/constants/routes';
// files
import DeliverableContainer from '~/features/deliverables/components/DeliverableContainer';
import { sendGoogleAnalyticsEvent } from '~/index';

export const DeliverablesRoute = ({ match }) => {
    // send Google Analytics Event
    sendGoogleAnalyticsEvent('Deliverables Page', 'View Deliverables');
    return (
        <Switch>
            <Route path={`${match.path}/:deliverableType?/:deliverableId?/:deliverableVersion?`} render={(props) => <DeliverableContainer {...props}/>}/>
            <Route path={`${match.path}/${DELIVERABLES_CM4G_PATH}`} component={(props) => <DeliverableContainer {...props}/>}/>
            <Route path={`${match.path}/${DELIVERABLES_FILES_PATH}`} component={(props) => <DeliverableContainer {...props}/>}/>
            <Route path={`${match.path}/${DELIVERABLES_BUNDLES_PATH}`} component={(props) => <DeliverableContainer {...props}/>}/>
        </Switch>
    );
};

export default DeliverablesRoute;
