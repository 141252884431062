import {
    entityStoreSelector,
    indicesStoreSelector,
    loadingStoreSelector,
} from '~/features/higherorder/selectors/dataSelectors';
import { createSelector } from 'reselect';
import {
    ENTITY_ARTIFACT,
    ENTITY_ARTIFACT_SCOPE_DEVICES,
    ENTITY_DELIVERABLES_CONTEXT_INFO,
} from '~/features/base/constants/entities';
import getOr from 'lodash/fp/getOr';
import get from 'lodash/fp/get';

export const artifactsLoadingSelector = state => loadingStoreSelector(state)['artifacts'];

export const artifactEditorSelector = state => state.ui.artifactEditor;

export const artifactEntitiesSelector = state => entityStoreSelector(state)[ENTITY_ARTIFACT];
const artifactPagesSelector = state => getOr({}, ENTITY_ARTIFACT_SCOPE_DEVICES,
    indicesStoreSelector(state)[ENTITY_ARTIFACT]);
export const artifactPageNumberSelector = state => get('number', artifactPagesSelector(state));

export const artifactPageItemsSelector = createSelector(
    [artifactPagesSelector, artifactEntitiesSelector, artifactPageNumberSelector],
    (pages, entities, pageNumber) => {
        return entities;
    },
);
export const deliverableContextInfoEntitiesSelector = state => entityStoreSelector(
    state)[ENTITY_DELIVERABLES_CONTEXT_INFO];
