import { call, put, select, takeLatest } from 'redux-saga/effects';

import { CONTROL_DEVICE_EDITOR_MODAL } from '~/features/base/constants/modals';

import { showModal } from '~/features/base/actions/ui/modalsActions';
import {
    changeControlDeviceEditor,
    resetControlDeviceEditor,
    SHOW_CONTROL_DEVICE_EDITOR,
} from '~/features/devices/actions/controlDeviceEditorActions';
import { fetchControlDevice } from '~/features/devices/actions/controlDeviceActions';
import { fetchDeviceGroups, fetchFilteredGroups } from '~/features/groups/actions/groupActions';

import { controlDeviceInfoSelector, controlDeviceSelector } from '~/features/devices/selectors/controlDeviceSelectors';

import { doFetchControlDeviceSaga } from '~/features/devices/sagas/controlDeviceSaga';
import { doFetchDeviceGroupsSaga, doFetchFilteredGroupsSaga } from '~/features/groups/sagas/groupSaga';

export function* doPrepareForNewControlDeviceSaga() {
    yield put(changeControlDeviceEditor({
        isNew: true,
    }));
}

export function* doPrepareForExistingControlDeviceSaga(payload) {
    const serialNumber = payload.serialNumber;
    yield call(doFetchControlDeviceSaga, fetchControlDevice({ serialNumber }));
    yield call(doFetchDeviceGroupsSaga, fetchDeviceGroups({ serialNumber }));
    yield call(doFetchFilteredGroupsSaga, fetchFilteredGroups());
    const controlDevice = yield select(controlDeviceSelector, { serialNumber });
    const controlDeviceInfo = yield select(controlDeviceInfoSelector, { serialNumber });
    const vehicleGroupNames = controlDeviceInfo.vehicleGroupNames || [];
    yield put(changeControlDeviceEditor({
        isNew: false,
        isValidSerialNumber: true,
        originalControlDevice: controlDevice,
        type: controlDevice.type,
        serialNumber: controlDevice.serialNumber,
        inCustomerFleet: controlDevice.inCustomerFleet || false,
        updatesActive: controlDevice.updatesActive || false,
        testReleasesActive: controlDevice.testReleasesActive || false,
        originalDeviceGroups: vehicleGroupNames,
        deviceGroups: vehicleGroupNames,
        description: controlDevice.description,
    }));
}

export function* doShowControlDeviceEditorSaga(action) {
    yield put(resetControlDeviceEditor());
    if (action.payload.isNew) {
        yield call(doPrepareForNewControlDeviceSaga, action.payload);
    } else {
        yield call(doPrepareForExistingControlDeviceSaga, action.payload);
    }
    yield put(showModal({
        modalType: CONTROL_DEVICE_EDITOR_MODAL,
        modalProps: {},
    }));
}

export function* showControlDeviceEditorSaga() {
    yield takeLatest(SHOW_CONTROL_DEVICE_EDITOR, doShowControlDeviceEditorSaga);
}
