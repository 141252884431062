import { EVENTS_PATH, OVERVIEW_PATH } from '~/features/base/constants/routes';

import React, { PureComponent } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import SidebarContainer from '~/features/base/components/SidebarContainer';
import UpdateEventsFiltersContainer from '~/features/updateEvents/components/UpdateEventFiltersContainer';

export const SidebarContainerRoute = () => {
    return (
        <SidebarContainer/>
    );
};
export const UpdateEventsFiltersRoute = () => {
    return (
        <SidebarContainer>
            <UpdateEventsFiltersContainer/>
        </SidebarContainer>
    );
};



export class SidebarRoutes extends PureComponent {
    render() {
        const { match } = this.props;
        return (
            <div className='display-flex height-100pct'>
                <Switch>
                    <Route path={`${match.path}${OVERVIEW_PATH}/${EVENTS_PATH}`}
                           component={UpdateEventsFiltersRoute}/>
                    <Route path={match.path} component={SidebarContainerRoute}/>
                </Switch>
            </div>
        );
    }
}

export default withRouter(SidebarRoutes);
