import getOr from 'lodash/fp/getOr';
import noop from 'lodash/fp/noop';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

import {
    changeDeliverableEditor,
    createDeliverable,
    updateDeliverable,
} from '~/features/deliverables/actions/deliverableActions';
import {
    deliverablesContextsSelector,
    deliverableTypeSelector,
} from '~/features/deliverables/selectors/deliverableSelectors';
import { DELIVERABLE_SCHEMA } from '~/features/deliverables/constants/deliverablesParameters';
import DeliverableVersionEditorForm from '~/features/deliverables/components/DeliverableVersionEditorForm';

/**
 * Deliverable version editor dialog
 */
export class DeliverableVersionEditorDialog extends PureComponent {
    onSaveDeliverable = () => {
        const { model, deliverableType } = this.props;

        // if (!this.isValid(model)) {
        //     return;
        // }
        if (model.isNew) {
            const newDeliverable = {
                'context': model.context,
                'deliverableName': model.deliverableName,
                'schema': DELIVERABLE_SCHEMA,
                'type': deliverableType,
                'file': model.file,
            };
            this.props.createDeliverable(newDeliverable);
        } else {
            this.props.updateDeliverable(model);
        }
    };

    isValid(model) {
        return !!model.deliverableName;
    }

    render() {
        const { model, contexts } = this.props;
        const title = this.renderTitle(model);
        const body = this.renderBody(model, contexts);
        const footer = this.renderFooter(model);
        return (
            <Dialog className='file-deliverable-editor-dialog'
                    show={true}
                    showCloseButton={true}
                    onHide={this.props.hideModal}
                    title={title}
                    body={body}
                    footer={footer}
                    useOverflow
            />
        );
    }

    renderTitle(model) {
        const isNew = getOr(true, 'isNew', model);
        if (isNew) {
            return <FormattedMessage id='intl-msg:createVersion'/>;
        }
        return <FormattedMessage id='intl-msg:editDeliverable.title' values={{
            deliverableName: model.deliverableName,
        }}/>;
    }

    renderBody(model, contexts) {
        return (
            <DeliverableVersionEditorForm model={model}
                                          deliverableType={this.props.deliverableType}
                                          changeDeliverableEditor={this.props.changeDeliverableEditor}/>
        );
    }

    renderFooter(model) {
        const isValid = true;
        const isInProgress = getOr(false, 'isInProgress', model);

        const isReady = isValid && !isInProgress;
        return (
            <div>
                <button className='btn btn-default' onClick={this.props.hideModal}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
                <button className={classNames('btn btn-primary', { disabled: !isReady })}
                        onClick={this.onSaveDeliverable}>
                    {
                        isInProgress ?
                            <Spinner text={<FormattedMessage id='intl-msg:save'/>}/> :
                            <FormattedMessage id='intl-msg:save'/>
                    }
                </button>
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        deliverableType: deliverableTypeSelector(state),
        contexts: deliverablesContextsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        createDeliverable: (newDeliverable) => {
            dispatch(createDeliverable(newDeliverable));
        },
        updateDeliverable: (softwareVersion) => {
            dispatch(updateDeliverable(softwareVersion));
        },
        changeDeliverableEditor: payload => {
            dispatch(changeDeliverableEditor(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableVersionEditorDialog);

DeliverableVersionEditorDialog.defaultProps = {
    // props
    model: {},
    contexts: [],
    // functions
    createDeliverable: noop,
    updateDeliverable: noop,
    changeDeliverableEditor: noop,
};

DeliverableVersionEditorDialog.propTypes = {
    // props
    model: PropTypes.object,
    contexts: PropTypes.array,
    // functions
    createDeliverable: PropTypes.func,
    updateDeliverable: PropTypes.func,
    changeDeliverableEditor: PropTypes.func,
};
